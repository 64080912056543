import React from 'react';

class Error extends React.Component {

    componentDidMount() {

    }

    constructor(props) {
        super(props);

    }


render() {
  return (
        <div>
          <br></br><br></br>
          <div className="text-center">
              <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
          </div>
          <center>{this.props.loadingMessage}</center>


          <br></br><br></br>
      </div>


  );
    }
}

export default Error;
