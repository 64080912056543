import React from 'react';
import ReactDOM from 'react-dom';

import Homepage from './Homepage';
import LoginView from './LoginView';
import Dashboard from './Dashboard';
import ServiceAuth from './ServiceAuth';


class App extends React.Component {

    constructor(props) {
        super(props);

        this.core = this.props.LDSCore;
        this.core.events.preemptiveBind('authChanged',this.authChanged.bind(this));

        this.state = {
            mainPage:"homepage",
        };
        this.urlParams = null;
        this.nextPage = null;

        this.authPaths = ['/twitch_auth_basic','/twitch_auth_advanced','/twitch_mod_auth_advanced','/youtube_auth','/stream_elements_auth'];
    }

    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);
        this.urlParams = urlParams;

        //Look for special enpoints
        if(this.authPaths.includes(window.location.pathname)){
            this.grabAuthCredentials(window.location.pathname);
            this.setNewMainPage('3rd_party_auth',false);
            return;
        }
        

        //TODO this needs improved, see below
        this.core.connect();

        if(this.core.isUserLoggedIn()){
            switch (window.location.pathname) {
                case "/login":
                    this.setNewMainPage("dashboard");
                    break;
                case "/dashboard":
                    this.setNewMainPage("dashboard");
                    break;
                case "/twitch_auth":
                    this.setNewMainPage("dashboard");
                    break;
                case "/register":
                    this.urlParams.mode = "register";
                    this.setNewMainPage("login");
                    break;
                case "/forgot_password":
                    this.urlParams.mode = "forgot_password";
                    this.setNewMainPage("login");
                    break;
                default:
                    this.setNewMainPage('homepage');
            }
        }else{
            switch (window.location.pathname) {
                case "/login":
                    this.setNewMainPage("login");
                    break;
                case "/dashboard":
                    this.setNewMainPage("login");
                    break;
                case "/twitch_auth":
                    this.urlParams.mode = "twitch";
                    this.setNewMainPage("login");
                    break;
                case "/register":
                    this.urlParams.mode = "register";
                    this.setNewMainPage("login");
                    break;
                case "/forgot_password":
                    this.urlParams.mode = "forgot_password";
                    this.setNewMainPage("login");
                    break;
                case "/password_reset_verify":
                    this.urlParams.mode = "password_reset_verify";
                    this.setNewMainPage("login");
                    break;
                default:
                    this.setNewMainPage('homepage');
            }
        }

    }

    authChanged(signedIn){
        if(signedIn && this.state.mainPage=='login'){
            this.setNewMainPage('dashboard');
        }
    }

    grabAuthCredentials(type){
        const code = this.urlParams.get('code');
        const scope = this.urlParams.get('scope');
        console.log("HAVE PARAMS",code,scope);
        //set localstorage and close window, cant guarantee we have auth
        var creds = {
            code:code,
            scope:scope,
            type:type,
        }
        localStorage.setItem('auth_attempt', JSON.stringify(creds));
        console.log("CREDS STORED",creds);

        window.close();
    }

    setNewMainPage(page,clear=true,nextPage=null){

        this.nextPage = nextPage;

        if(clear){
            //TODO this needs improved, see above
            window.history.replaceState(null, "Last Day Studios - "+page, "/"+page);
        }

        this.setState({mainPage:page});
    }

    //TODO need to check if user is logged in to view some pages??
    //TODO need to set page title and pathname
    //TODO need to read page title and pathname to quick jump to pages
    //window.history.replaceState(null, "Last Day Studios - Dashboard", "/dashboard");
    //const code = urlParams.get('code');

    render() {

            switch (this.state.mainPage) {
                case "homepage":
                    return(<Homepage LDSCore={this.core} app={this} />);
                break;
                case "login":
                    return(<LoginView LDSCore={this.core} app={this} />);
                break;
                case "dashboard":
                    return(<Dashboard LDSCore={this.core} app={this} nextPage={this.nextPage} />);
                break;
                case "3rd_party_auth":
                    return(<ServiceAuth LDSCore={this.core} app={this} />);
                break;
                default:
                    return(
                        <div>
                          Error: Something went very wrong!
                        </div>
                    );
            }
        }
}

export default App;
