import React from 'react';

class RightMenu extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;


    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    signOut(){
        console.log("attempting signout");

        this.core.signOut(this.signOutCallback.bind(this));
    }
    signOutCallback(result){
        this.props.app.setNewMainPage('homepage');
    }
              

    render() {
            return(
                <div>
                    <div id="right-menu-loggedin">
                    <button onClick={this.signOut.bind(this)}>
                        Log Out
                    </button>
                    </div>
                </div>
            );
    }
}
export default RightMenu;
