import React from 'react';

class ForgotPassword extends React.Component {

    componentDidMount() {
    }

    constructor(props) {
        super(props);

        this.state = {
            identity: '',
            error: '',
            emailSent: false,
        };
        this.PasswordResetClickedRef = this.handlePasswordResetSubmit.bind(this);
        this.EmailChangeRef = this.handleEmailChange.bind(this);
    }

    handlePasswordResetSubmit(event){
        event.preventDefault();
        this.props.LDSCore.requestPasswordReset(this.state.identity,this.passwordResetCallback.bind(this));
    }

    passwordResetCallback(result,message){
        console.log(result,message);
        if(result==false){
          this.setState({error:message});
        }else{
          this.setState({emailSent:true});
        }
    }

    handleEmailChange(event) {
      this.setState({identity: event.target.value});
    }

    backToLogin(){
      this.props.parent.selectLoginView();
    }


    render() {

      if(this.state.emailSent){
        return (
          <div>
            <div className='alert alert-success'>
              A password recovery email has been sent. Please check your inbox and spam folder for a link to reset your password.
            </div>

            <br></br>
            <button className="btn btn-light btn-block w-100 " onClick={this.backToLogin.bind(this)}>
              Back To Login
            </button>

          </div>
            );
      }

      return (
            <div>
              <br></br><br></br>
              <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
              </div>

              <form onSubmit={this.PasswordResetClickedRef}>

                {this.state.error!="" ?
                  <div className='alert alert-danger'>
                    {this.state.error}
                  </div>
                  :
                null
                }

                <div className="alert alert-info" role="alert">
                  <i className="dripicons-information mr-2"></i> <strong> Please type your email address:</strong>  If an account with that email address exists we'll send you a password reset link!
                </div>

                  <div>

                  </div>

                  <br></br>

                  <div className="better-form-group">
                      <label htmlFor="identity">Your Account Email Address</label>
                      <input type="text" id="identity" className="better-form-control  " aria-describedby="Username or Email" placeholder="Email" value={this.state.identity} onChange={this.EmailChangeRef}/>
                  </div>

                  <br></br>

                  <div className="row">
                    <div className="col-lg-6">
                          <button className="btn btn-danger btn-block w-100" >
                              Send Account Recovery Link
                          </button>
                     </div>
                     <div className="col-lg-6">
                          <button className="btn btn-light btn-block w-100 " onClick={this.backToLogin.bind(this)}>
                              Back To Login
                          </button>
                     </div>


                  </div>
              </form>


              <br></br><br></br>
              </div>


        );
      }
}

export default ForgotPassword;
