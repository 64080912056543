import React from 'react';
import { config } from '../../../config'; 
import $ from 'jQuery';


class Team extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(){
        $('#jess_email').attr("href", "mailto:jess@lastdaystudios.com");
        $('#jess_email').html("jess@lastdaystudios.com");
    }

    render() {
      return (
          <section className="py-5 bg-light">
            <a id="team"></a>
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="text-center">
                              <h1 className="mt-0"><i className="mdi mdi-heart-multiple-outline"></i></h1>
                              <h3 className="text-black">We Apreciate <span className="text-danger">YOU</span></h3>
                              <p className="text-dark fw-bold mt-2">Making games and content takes a really long time! We appeciate your patience and support!
                              </p>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-4">
                      <div className="card card-pricing  align-items-center" style={{height:'700px'}}>
                                <div className="card-body text-center">
                                    <h1 className="text-dark fw-bold text-uppercase">JessGGs</h1>
                                    <img src={config.paths.cdn_base+'/images/pfp/jess-pfp.jpg'} className="rounded-circle img-thumbnail" width="300px" alt="profile-image"></img>
                                    <h3 className="card-pricing-price text-black">Game Developer</h3>
                                    <p className="text-muted fw-bold">
                                    Jess has been creating video games since she was 8, but... Last Day To Party is going to be the first one she's published. Good thing it's a game about the apocalypse because this could be a disaster.
                                    </p>
                                    <p className="text-success fw-bold">
                                    Find Jess live on Twitch for game dev streams!<br></br> Or join the game dev Discord community
                                    </p>
                                    <a className="d-inline" href=""><img src={config.paths.cdn_base+'/images/social/border/twitch.png'} className="social-icon" alt="twitch"></img></a>
                                        <a className="d-inline" href="https://www.twitch.tv/jessggs"><img src={config.paths.cdn_base+'/images/social/border/youtube.png'} className="social-icon" alt="youtube"></img></a>
                                        <a className="d-inline" href="https://twitter.com/Jess_GGs"><img src={config.paths.cdn_base+'/images/social/border/twitter.png'} className="social-icon" alt="twitter"></img></a>
                                        <a className="d-inline" href="https://discord.gg/HtPvrTT"><img src={config.paths.cdn_base+'/images/social/border/discord.png'} className="social-icon" alt="discord"></img></a>
                                        <a className="d-inline d-none" href=""><img src={config.paths.cdn_base+'/images/social/border/patreon.png'} className="social-icon" alt="patreon"></img></a>
                                        <a className="d-inline d-none" href=""><img src={config.paths.cdn_base+'/images/social/border/web.png'} className="social-icon" alt="website"></img></a>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-pricing  align-items-center">
                                <div className="card-body text-center">
                                    <h1 className="text-dark fw-bold text-uppercase">Jonsandman</h1>
                                    <img src={config.paths.cdn_base+'/images/pfp/jon-pfp.jpg'} className="rounded-circle img-thumbnail" width="300px" alt="profile-image"></img>
                                    <h3 className="card-pricing-price text-dark">Community Manager & Idea Guy</h3>
                                    <p className="text-muted fw-bold">
                                    Jon is an accomplished full-time content creator and dedicated streamer with a burning passion for crafting the ultimate community-driven party game. 
                                    His dream is to create the ultimate customizable party game with
                                    an intuitive map editor, mutators, and an extensive customizable character system.
                                    </p>
                                    <p className="text-success fw-bold">
                                    Catch Jon live on Twitch 4 nights a week!<br></br> (Sun, Tue, Thurs, Fri) PST
                                    </p>
                                    <div className="">
                                        <a className="d-inline" target="_blank" href="https://www.twitch.tv/jonsandman"><img src={config.paths.cdn_base+'/images/social/border/twitch.png'} className="social-icon" alt="twitch"></img></a>
                                        <a className="d-inline" target="_blank" href="https://www.youtube.com/@JonSandman"><img src={config.paths.cdn_base+'/images/social/border/youtube.png'} className="social-icon" alt="youtube"></img></a>
                                        <a className="d-inline" target="_blank" href="https://twitter.com/Jonsandman"><img src={config.paths.cdn_base+'/images/social/border/twitter.png'} className="social-icon" alt="twitter"></img></a>
                                    </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="card p-4"  style={{height:'700px'}}>
                        <h3 className="fw-bold text-info">Get Involved!</h3>
                          <h3 className="fw-bold text-info">Interested in Game Dev?</h3>
                          
                          <p className="text-dark fw-bold mt-3">Game dev takes a community, find us on <a href="" >Twitch</a>. and <a href="" >Discord</a> and help create the game of 2024: 
                          </p>

                          <div className="mt-1">
                          <p className="text-muted m-0"><i className="mdi mdi-circle-medium text-primary"></i> Help Test The Game</p>
                          <p className="text-muted m-0"><i className="mdi mdi-circle-medium text-primary"></i> Share & Inspire Ideas</p>
                          <p className="text-muted m-0"><i className="mdi mdi-circle-medium text-primary"></i> Provide Feedback</p>
                            <p className="text-muted m-0"><i className="mdi mdi-circle-medium text-primary"></i> Community Level Editor</p>
                              
                          </div>

                          <a href="" className="btn btn-primary btn-rounded mt-3">Join the fun!<i className="mdi mdi-arrow-right ms-1"></i></a>

                          </div>
                      </div>

                    
                  </div>
                  <a id="contact"></a>
                  <hr></hr>
                    <div className="row">
                        <div className="col text-center">
                            <h2 className="text-primary">CONTACT US</h2>
                            <br></br>
                            Feel free to reach out on social media or email professional inquiries to <a id="jess_email" href=""></a>

                        </div>
                    </div>
              </div>
          </section>
      );
    }
}

export default Team;
