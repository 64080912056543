import React from 'react';
import $ from 'jQuery';
import { config } from '../../../../config';


import Breadcrumb from '../components/Breadcrumb';
import GameCarousel from '../components/GameCarousel';
import GameCarouselPreview from '../components/GameCarouselPreview';
import PurchaseCard from '../components/store/PurchaseCard';
import ExternalContent from '../components/ExternalContent';


class GameOverview extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
        this.gameId = this.props.gameId;
        this.parent = this.props.parent;

        this.state = {
            gameData: null,
            screenshotsArr: null,
            mode: 'carousel',
            owner: false,
        }

    }

    componentDidMount() {

        //Get game
        var data = this.core.getStoreGame(this.gameId);
        if(!data){
            return;
        }

        var isOwner = this.core.isProductInUsersLibrary('game',data.uuid);

        this.setState({gameData:data,owner:isOwner});
        console.log("GOT GAME",data);

        //TODO debug delete these
        data.web_background_url=null;

        if(data.web_background_url){
            document.body.style.backgroundImage = 'url('+data.web_background_url+')';
            document.body.classList.add("product-overview-bg");
        }



    }



   
    componentWillUnmount() {
        document.body.classList.remove("product-overview-bg");
        document.body.style.backgroundImage = null;
    }

    backToGallary(){
        this.setState({mode:'carousel'});
    }

    openTrailerVideo(){
        console.log("CALLED OPEN");
        this.setState({mode:'trailer'});
        setTimeout(this.playTrailerVideo,200);
    }

    playTrailerVideo(){
        console.log("CALLED");
        $('#trailer_video').trigger('play');
    }

    openGameplayVideo(){
        this.setState({mode:'gameplay'});
        setTimeout(this.playGameplayVideo,200);
    }

    playGameplayVideo(){
        console.log("CALLED");
        $('#gameplay_video').trigger('play');
    }

    openGalery(){
        this.backToGallary();
    }

    playNow(){
        this.parent.playNow(this.state.gameData.store_title);
    }

    render() {
        

        if(!this.state.gameData){
            return(
                <div className='content'>
                        <div className='container-fluid'>

                           Loading

    
                        </div>
                    </div>
            );
        }



            return(
                <div className='content'>

                        {this.state.gameData.header_url ?
                            <img className='game-overview-header-image' src={this.state.gameData.header_url}></img>
                        :null}

                        <div className='container container-fluid '>
                                
                            <Breadcrumb title={this.state.gameData.store_title} pageTree={{Store:'#','Game Overview':'#'}} style="light"> </Breadcrumb>
                            

                            <div className='row'>
                               
                                <div  className='col-md-8'>

                                    <div className='product-page-spacer mt-4 d-none d-sm-none d-xs-none d-md-block '></div>
                                    <div className=" d-md-none d-lg-none d-xl-none">
                                            <h1 className="text-white">{this.state.gameData.store_title}</h1>
                                            <div className='text-white'>
                                                {this.state.gameData.store_description}
                                            </div>
                                        </div>
                                    

                                   <div className=' '>
                                    <div className='card-body'>
                                        <div className="d-none d-sm-none d-xs-none d-md-block">
                                            <h1 className="text-dark">{this.state.gameData.store_title}</h1>
                                            <div className='text-secondary'>
                                                {this.state.gameData.store_description}
                                            </div>
                                        </div>

                                        <br></br>

                                        {this.state.owner ?
                                            <div className='alert alert-success p-1 text-black'>
                                                <div className='btn btn-success me-1' onClick={this.playNow.bind(this)}>Play Now</div> 
                                                <strong>{this.state.gameData.store_title} is already in your game library! </strong>
                                                </div>
                                        : null }

                                        {this.state.mode=='carousel' ?
                                            <GameCarousel key="game_carousel" gameData={this.state.gameData} parentElement={this}></GameCarousel>
                                        : null }

                                        {this.state.mode=='trailer' ?
                                        <video id="trailer_video" className="w-100" poster={config.paths.cdn_base+'/images/trailer_poster.jpg'} autoPlay controls>
                                            <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.webm'} type="video/webm"/>
                                            <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.mp4'} type="video/mp4"/>
                                        </video>
                                        : null}

                                        {this.state.mode=='gameplay' ?
                                        <video id="gameplay_video"  className="w-100" poster={config.paths.cdn_base+'/images/trailer_poster.jpg'} autoPlay controls>
                                            <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.webm'} type="video/webm"/>
                                            <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.mp4'} type="video/mp4"/>
                                        </video>
                                        : null}

                                        <GameCarouselPreview core={this.core} key="game_carousel_preview" gameData={this.state.gameData} parentElement={this}></GameCarouselPreview>

                                        <br></br>

                                        <ExternalContent core={this.core} url={this.state.gameData.store_page_content_url}></ExternalContent>

                                    </div>
                                 
                                   </div>
                
                                </div>

                                <div className='col-md-4'>
                                <div className='product-page-spacer mt-4 d-none d-sm-none d-xs-none d-md-block '></div>
                                <PurchaseCard 
                                    purchaseImage={this.state.gameData.game_cover_card} 
                                    price={this.state.gameData.price}  
                                    discount={this.state.gameData.discount}  
                                    type='game' 
                                    uuid={this.state.gameData.uuid} 
                                    isOwned={this.state.owner}
                                    parent={this}
                                    playable={true}
                                    title={this.state.gameData.store_title}
                                    >
                                        
                                </PurchaseCard>
                                </div>

                            </div>

                        </div>
                    </div>
            );
    }
}
export default GameOverview;
