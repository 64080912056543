import React from 'react';
import Footer from '../../both/Footer';

class AccountSettings extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
            return(
                <div>
                    <div className='content'>
                        <div className='container-fluid'>
                            <br></br>
                            <div className="alert alert-secondary bg-secondary-lighten">Account Settings Coming Soon</div>

                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            );
    }
}
export default AccountSettings;
