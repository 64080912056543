import React from 'react';

class PriceCard extends React.Component {

    constructor(props) {
        super(props);

        this.price = this.props.price;
        this.discount = this.props.discount;
        this.currencyType = this.props.currencyType;
        this.title = this.props.title;
        this.truePrice = this.price;
        if(this.discount>0){
            this.truePrice = Math.round(this.price*(1-this.discount)* 100)/100;
        }

        if(this.truePrice==0){
            this.truePrice="FREE";
        }

        //<del>{this.price}</del>

         this.currencySymbol = '$';
         switch (this.currencyType) {
             case 'usd':
                    if(this.truePrice=='FREE'){
                        this.currencySymbol = "";
                    }else{
                        this.currencySymbol = "$";
                    }
                 break;
             case 'tokens':
                 this.currencySymbol = <div className='price-shift me-1'><img className="apocalypse-token-icon"></img></div>;
                 break;
             case 'free_tokens':
                 this.currencySymbol = <div className='price-shift me-1'><img className="free-token-icon"></img></div>;
                 break;
             default:
                 break;
         }
         

        this.state = {
        };
    }



    render() {
      return (
        <div className="item_title_price p-0 text-black">
            <table>
                <tbody>
                    <tr>
                        {this.discount!=0 ?
                            <td className="discount storeTitleCell ps-1 pe-1">-{this.discount*100}%</td> 
                        : null }

                        {this.discount!=0 ?
                            <td className="storeTitleCell pricecell ps-1 pe-1 pricecell-sale " >

                                <div className="nowrap">{this.currencySymbol} <del>{this.price}</del></div>

                                <div className="nowrap">{this.currencySymbol} {this.truePrice}</div>

                            </td>
                        :
                            <td className="storeTitleCell pricecell ps-1 pe-1">
                                <div className="nowrap price-row-shift">{this.currencySymbol}<div className=''>{this.truePrice}</div></div>
                            </td>
                        }

                        <td className="text-right w-100 storeTitleCell namecell pe-1">
                            {this.title}
                        </td>

                    </tr>
                </tbody>
            </table>
            
    </div>
      );
    }
}

export default PriceCard;
