import React from 'react';
import GameTitleCard from './GameTitleCard';
import ItemCard from './ItemCard';
import ItemCardSmall from './ItemCardSmall';

class ItemRowWidget extends React.Component {

    constructor(props) {
        super(props);
        this.dashboard = this.props.dashboard;
        this.core = this.props.LDSCore;

        this.state = {
            itemCards: [],
            showFailedToLoad: false,
        }

        this.keyItr = 0;

        //Alias
        this.core = this.props.LDSCore;
    }

    componentDidMount() {
        this.refreshStoreItems();
    }

    componentWillUnmount() {
    }

    refreshStoreItems(){
        this.core.getStoreItems(this.storeItemsUpdated.bind(this));
    }

    storeItemsUpdated(data){
        console.log("GOT Item DATA IN WIDGET",data);
        if(!data || !Array.isArray(data)){
            this.setState({showFailedToLoad:true});
            return;
        }

        var itemCards = [];
        for (const item of data) {
            this.keyItr++;
            itemCards.push(<ItemCardSmall storeItemEntry={item} dashboard={this.dashboard} LDSCore={this.core} key={this.keyItr+"_store_game_card"} type="item"></ItemCardSmall>);
        }
        this.setState({itemCards:itemCards});

    }

    render() {
            return(
                <div>
                    <h3 className="text-black mb-0">Items:</h3>
                    <hr className='mt-0'></hr>
                    { this.state.showFailedToLoad ?
                    <div className='alert alert-warning text-black'><i className='mdi mdi-connection me-1'></i><strong>Error: </strong>Failed to download the item list from the server!</div>
                    : null}

                    <div className="item-grid-cards-sm overflow-visible">
                        {this.state.itemCards}
                    </div>
                </div>
            );
    }
}
export default ItemRowWidget;
