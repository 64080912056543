import React from 'react';
import AccountSettings from './pages/AccountSettings';
import Cataclysm from './pages/cataclysm_pages/Cataclysm';
import DashboardHome from './pages/DashboardHome';
import Launcher from './pages/Launcher';
import LinkAccount from './pages/LinkAccount';
import PublicProfile from './pages/PublicProfile';
import Store from './pages/Store';
import GameOverview from './pages/GameOverview';
import ItemOverview from './pages/ItemOverview';
import ProductOverview from './pages/ProductOverview';
import CataclysmSwitcher from './pages/CataclysmSwitcher';


class DashboardPageSwitcher extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
        this.parent = this.props.parent;
        //
        this.nextPage = this.props.nextPage;
        this.nextPageOpened = false;

        this.slectedGameId = null;
        this.slectedItemId = null;
        this.title = null;


        this.state = {
            page: 'store',
        }

        this.parent.pageSwitcher = this;

    }

    componentDidMount(){
        if(this.nextPage!=null && this.nextPageOpened==false){
            this.setPage(this.nextPage);
            this.nextPageOpened = true;
        }
    }

    setPage(page){
        if(page==this.state.page){
            return;
        }
        this.setState({page:page});
    }

    openProductPage(productType,productId){
        console.log("Opening Product",productType,productId);
        switch (productType) {
            case 'game':
                this.openGamePage(productId);
                break;
            case 'item':
                this.openItemPage(productId);
                break;
            case 'product':
                this.openStoreProductPage(productId);
                break;
        }
    }

    openItemPage(productId){
        if(productId=='empty'){
            this.setPage('store');
            return;
        }

        this.slectedItemId = productId;
        this.setPage('item_overview');
    }

    openStoreProductPage(productId){
        if(productId=='empty'){
            this.setPage('store');
            return;
        }

        this.selectedProductId = productId;
        this.setPage('product_overview');
    }

    openGamePage(productId){
        if(productId=='empty'){
            this.setPage('store');
            return;
        }

        this.slectedGameId = productId;
        this.setPage('game_overview');
    }

    playNow(title=null){
        this.title = title;
        this.setPage('launcher');

    }

    render() {

        switch (this.state.page) {
            case 'home':
                return(<DashboardHome LDSCore={this.core} dashboard={this}></DashboardHome>);
                break;
            case 'store':
                return(<Store LDSCore={this.core} dashboard={this}></Store>);
                break;
            case 'game_overview':
                return(<GameOverview LDSCore={this.core} gameId={this.slectedGameId} parent={this}></GameOverview>);
                break;
            case 'item_overview':
                return(<ItemOverview LDSCore={this.core} itemId={this.slectedItemId} parent={this}></ItemOverview>);
                break;
            case 'product_overview':
                return(<ProductOverview LDSCore={this.core} productId={this.selectedProductId} parent={this}></ProductOverview>);
                break;
            case 'launcher':
                return(<Launcher LDSCore={this.core} title={this.title}></Launcher>);
                break;
            case 'link_account':
                return(<LinkAccount LDSCore={this.core}></LinkAccount>);
                break;
            case 'public_profile':
                return(<PublicProfile LDSCore={this.core}></PublicProfile>);
                break;
            case 'account_settings':
                return(<AccountSettings LDSCore={this.core}></AccountSettings>);
                break;
            case 'cataclysm':
                return(<CataclysmSwitcher LDSCore={this.core} parent={this}></CataclysmSwitcher>);
                break;
            default:
                return(<div>ERROR</div>);
                break;
        }
            
    }
}
export default DashboardPageSwitcher;
