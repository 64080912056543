import React from 'react';

import Breadcrumb from '../components/Breadcrumb';
import Footer from '../../both/Footer';


class Launcher extends React.Component {

    constructor(props) {
        super(props);

        this.title = null;
        if(this.props.title){
            this.title = this.props.title;
        }

        //Alias
        this.core = this.props.LDSCore;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
            return(
                <div className='content'>
                        <div className='container-fluid'>

                        <Breadcrumb title="Launcher" pageTree={{Dashboard:'#',Launcher:'#'}}> </Breadcrumb>
                        
                        <h1 className="text-black">Last Day Studios Launcher</h1>

                        {this.title!=null ?
                            <div className='alert alert-primary fw-bold text-dark row m-0 mb-1 mt-1'>
                                <div className="col-auto">
                                    <i className='mdi-spin mdi mdi-rocket-launch mdi-24px d-inline'></i>
                                </div>
                                <div className="col-auto">
                                    <div className='d-inine play-now-message'>Download/Open the Last Day Studios Launcher to play {this.title}!</div>
                                </div>
                            </div>
                        : null}


                        <div className="alert alert-success" role="alert">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="btn btn-success w-100 text-black">
                                        <div className="row">
                                            <div className="col-sm-1">
                                                <i className="mdi mdi-download mdi-48px"></i>
                                            </div>
                                            <div className="col-sm-11">
                                                <h4 className="alert-heading mb-0">Download Our Launcher</h4>
                                                <small className="mt-0">(Windows Only) (Coming Soon)</small>
                                            </div>

                                        </div>
                                        
                                       
                                    </div>
                                </div>
                                <div className="col-md-9 text-black">
                                    <p className='mt-1 mb-0'><b>We have our own launcher!</b></p>
                                   
                                      
                                    <p className='mt-1 mb-0'><em>We still plan on a Steam release when LDTP leaves beta.</em></p>
                                </div>
                            </div>
                            
                            
                           
                        </div>

    
                                <div className="mb-2 mt-1">
                                    <img src='https://ldssite.b-cdn.net/images/launcher_preview.png' style={{maxWidth:'1000px'}}></img>
                                </div>



                     

                        <div className="row">
                            <div className="col-md-4">
                                <div className="card" style={{minHeight:'200px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title text-dark">
                                        Features:
                                    </h5>
                                        <ul>
                                            <li>Quickly update and patch our games and tools</li>
                                            <li>Manage multiple game versions</li>
                                            <li>Authenticate players</li>
                                            <li>Maybe have mod support someday??? :)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card" style={{minHeight:'200px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title text-dark">
                                        Launcher System Requirements:
                                    </h5>
                                        <ul>
                                            <li>500Mb of free space (For the Launcher)</li>
                                            <li>1Gb of Ram</li>
                                            <li>Windows 7, 8, 9, 10</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card" style={{minHeight:'200px'}}>
                                    <div className="card-body">
                                    <h5 className="card-title text-dark">
                                        LDTP System Requirements:
                                    </h5>
                                        <ul>
                                            <li>10Gb of free space</li>
                                            <li>8Gb of Ram</li>
                                            <li>Windows 7, 8, 9, 10</li>
                                            <li><b>AT LEAST A:</b> GTX 1060 or AMD RX 580</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        </div>
                        <Footer></Footer>
                    </div>
            );
    }
}
export default Launcher;
