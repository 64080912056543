import React from 'react';

class TokenWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
      return (
        <div className='border border-dark' style={{backgroundColor:'#005B16'}}>

            <div className='row text-white'>
                <div className='col text-right w-100 fw-bold token-widget-user-name ms-2'>
                    <span className='text-right'>JessGGs:</span>
                </div>
                <div className='col  token-cell'>
                    <img className="apocalypse-token-icon-md"></img><span className='token-cell-value fw-bold'>1000</span>
                </div>
                <div className='col  token-cell me-2'>
                    <img className="free-token-icon-md"></img><span className='token-cell-value fw-bold'>1000</span>
                </div>
                    
            </div>
          </div>
      );
    }
}

export default TokenWidget;
