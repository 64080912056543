
import Verification from "../../helpers/Verification.js";

export default class Cataclysm{

    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;

        this.webRequest = webRequest;

        this.data = null;

        //Create events
        this.events.createEvent('cataclysmUpdated');

    }

    connect(){
        
    }

    downloadCataclysmData(){
        this.webRequest.basic('cataclysm/get_users_data',this.processCataclysmData.bind(this),null,null);
    }

    processCataclysmData(res){
        console.log("GOT CATACLYSM DATA",res);

        if(res==undefined){
            return;
        }
        if(res.status=='success' && res.data!=undefined){
            console.log("GOT CATACLYSM DATA",res.data);
           this.data = res.data;
        }
        this.events.cataclysmUpdated.trigger(this.data);
    }

    getCataclysmValue(attributeName){
       
    }

    getCataclysmConfig(){
        if(!this.data || !this.data.cataclysm_account || !this.data.cataclysm_account.config){
            console.log("Error getting cataclysm config!");
            return null;
        }
        console.log("CONFIG",this.data.cataclysm_account.config);
       return this.data.cataclysm_account.config;
    }

    isCataclysmActive(){
       if(this.data!=null){
        return true;
       }
       return false;
    }

    signup(){
        this.webRequest.basic('cataclysm/signup',this.signupCallback.bind(this),null,null);
    }

    signupCallback(res){
        console.log("SIGN UP RES",res);
        this.downloadCataclysmData();
    }

    shutdown(){
        this.webRequest.basic('cataclysm/shutdown',this.shutdownCallback.bind(this),null,null);
    }

    shutdownCallback(res){
        console.log("Shutdown RES",res);
        this.downloadCataclysmData();
    }

    getBans(callback){
        this.webRequest.basic('cataclysm/ban/get_all_updated',this.processBans.bind(this,callback),null,null);
    }

    processBans(callback,res){
        if(res && res.status == 'success' && res.data){
            callback(res.data);
            return;
        }
        callback(null);
    }

    addBan(params){
        this.webRequest.basic('cataclysm/ban/add',this.addBanCallback.bind(this),params,null);
    }

    addBanCallback(res){
        if(res && res.status == 'success'){
            console.log("Ban added successfuly");
            return;
        }
        console.log("ban add failure",res);

    }

    removeBan(params){
        this.webRequest.basic('cataclysm/ban/remove',this.removeBanCallback.bind(this),params,null);
    }

    removeBanCallback(res){
        if(res && res.status == 'success'){
            console.log("Ban removed successfuly");
            return;
        }
        console.log("ban remove failure",res);
    }

    updateCataclysmConfig(config,callback){
        this.webRequest.basic('cataclysm/config/update',this.processUpdateConfigResults.bind(this,callback),config,null);
    }

    processUpdateConfigResults(callback,res){
        if(res && res.status == 'success' && res.data){
            console.log("FAILED TO SAVE",res);
            callback('error');
            return;
        }
        callback('success');
    }

}
