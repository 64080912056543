import React from 'react';

import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'

import { config } from "./../../../../config";



class NavSecondary extends React.Component {


    constructor(props) {
        super(props);

        this.core = this.props.LDSCore;
        this.parent = this.props.parent;
        
        this.state = {
            page: 'store',
        };
        
    }

    setPage(page){
        this.parent.setPage(page);
        this.setState({page:page});
    }

    //If the page was changed externally
    pageChanged(page){
        this.setState({page:page});
    }

    render() {

        //Conditional Formatting
        this.cc = {};
        const base = 'nav-link dropdown-toggle arrow-none';
        this.cc.home = base+(this.state.page=='home' ? " active" : "");
        this.cc.store = base+(this.state.page=='store' ? " active" : "");
        this.cc.launcher = base+(this.state.page=='launcher' ? " active" : "");
        this.cc.link = base+(this.state.page=='link_account' ? " active" : "");
        this.cc.public = base+(this.state.page=='public_profile' ? " active" : "");
        this.cc.account = base+(this.state.page=='account_settings' ? " active" : "");
        this.cc.cataclysm = base+(this.state.page=='cataclysm' ? " active" : "");

      return (
        <div className="topnav bg-dark  border-top border-secondary">
        <div className="container container-fluid">
                <nav className="navbar navbar-dark navbar-expand-lg topnav-menu">

                    <div className="collapse navbar-collapse active" id="topnav-menu-content">
                        <ul className="navbar-nav">

                        <li className="h5 nav-item dropdown">
                                <a className={this.cc.store} href="#" onClick={this.setPage.bind(this,'store')}>
                                    <i className="mdi mdi-store pe-1"></i>Store
                                </a>
                            </li>

                            <li className="h5 nav-item dropdown">
                                <a className={this.cc.home} href="#" onClick={this.setPage.bind(this,'home')}>
                                    <i className="mdi mdi-monitor-dashboard pe-1"></i> <span className="ml-2">Your Library</span>
                                </a>
                            </li>

                            <li className="h5 nav-item dropdown">
                                <a className={this.cc.launcher} href="#" onClick={this.setPage.bind(this,'launcher')}>
                                    <i className="mdi mdi-rocket-launch-outline pe-1"></i>Launcher
                                </a>
                            </li>

                            <li className="h5 nav-item dropdown">
                                <a className={this.cc.link} href="#" onClick={this.setPage.bind(this,'link_account')}>
                                    <i className="mdi mdi-key-link pe-1"></i>Link Account
                                </a>
                            </li>

                            <li className="h5 nav-item dropdown">
                                <a className={this.cc.public} href="#" onClick={this.setPage.bind(this,'public_profile')}>
                                    <i className="mdi mdi-account-circle pe-1"></i>Public Profile
                                </a>
                            </li>

                            <li className="h5 nav-item dropdown">
                                <a className={this.cc.account} href="#" onClick={this.setPage.bind(this,'account_settings')} >
                                    <i className="mdi mdi-cog-outline pe-1"></i>Account Settings
                                </a>
                            </li>

                            <li className="h5 nav-item dropdown">
                                <a className={this.cc.cataclysm} href="#" onClick={this.setPage.bind(this,'cataclysm')} >
                                    <i className="mdi mdi-lightning-bolt pe-1"></i>Cataclysm <div className="arrow-down"></div>
                                </a>

                            </li>
                            
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
        );
    }
}

export default NavSecondary;
