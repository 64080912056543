import React from 'react';
import { config } from "./../../../../../config";

class GameTitleCard extends React.Component {

    constructor(props) {
        super(props);
        //Alias
        this.dashboard = this.props.dashboard;
        this.core = this.props.LDSCore;

        this.fullStoreEntry = this.props.storeGameEntry;
        //Defaults
        this.ribbonColor = 'ribbon-primary';


        //Check for a full game entry to intialize from
        if(this.fullStoreEntry){
            console.log(this.fullStoreEntry);
            this.coverImage = this.fullStoreEntry.game_cover_card;

            if(this.fullStoreEntry.price==0.0){
                this.ribbonText = "Play For Free!";
                this.ribbonColor = 'ribbon-success';
            }

            this.productType = 'game';
            this.productId = this.fullStoreEntry.uuid;
            
        }else{
            //otherwise do it piecemeal
            if(this.props.coverImage){
                this.coverImage = this.props.coverImage;
            }
    
            if(this.props.ribbonColor){
                this.ribbonColor = this.props.ribbonColor;
            }
            if(this.props.ribbonText){
                this.ribbonText = this.props.ribbonText;
            }
            if(this.props.productType){
                this.productType = this.props.productType;
            }
            
            if(this.props.productId){
                this.productId = this.props.productId;
            }
        }
        
        this.state = {
        };
    }

    cardClicked(){
        this.dashboard.openProductPage(this.productType,this.productId);
    }

    render() {
        return (
            <div className="card ribbon-box game-title-card  clickable"  onClick={this.cardClicked.bind(this)}>
                <div className="card-body p-0 m-0">
                    <img className='game-title-card-bg card-shimmer-effect' src={this.coverImage}></img>
                    
                    { this.ribbonText ?
                    <div className={this.ribbonColor+" ribbon float-end mt-2 game-title-ribbon text-dark"}><i className="mdi mdi-google-controller me-1"></i>{this.ribbonText}</div>
                    : null}

                </div>
            </div>
      );
    }
}
export default GameTitleCard;