import React from 'react';

import Login from './Login';

import LoadingScreen from './LoadingScreen';
import CreateAccount from './CreateAccount';
import ForgotPassword from './ForgotPassword';
import TermsAndConditions from './TermsAndConditions';
import Error from './Error';
import PasswordReset from './PasswordReset';

class LoginCard extends React.Component {

    constructor(props) {
        super(props);

        //Core alias
        this.core = this.props.LDSCore;

        this.state = {
            user: null,
            appView: "app_initialization",
        };

        this.loginCallback = this.loginCallback.bind(this);
        this.displayNameSetCallback = this.displayNameSetCallback.bind(this);
        this.missingAccountData = null;

        this.accountUpdatedEvent = this.core.events.userUpdated.bindEvent(this.userUpdatedEvent.bind(this));

        //Count the number of connection attempts failed
        this.connectionFailedCounter = 0;
        this.nextConnectionAttemptIn = 100;

        //Paths config
        this.params = this.props.app.urlParams;
        this.resetToken = this.params.get('reset_token');

        this.connectRetryMessageTimer = null;
        this.showRetryMessageTimer = null;
    }

  
    componentDidMount() {
        this.check_for_connection();
    }

    componentWillUnmount() {
        clearTimeout(this.connectRetryMessageTimer);
        clearTimeout(this.showRetryMessageTimer);
        this.core.events.userUpdated.unbindEvent(this.accountUpdatedEvent);
    }

    check_for_connection(){
        var connected = this.core.isConnected();
        console.log("CHECKING FOR CONNECTION",connected);


        if(connected){
            console.log("Backend is ready")

            if(this.core.isUserLoggedIn()){
                this.state.appView = "waiting_for_account_data";
                this.forceUpdate();

                this.processUserData();
            }else{
                this.core.events.userStateChanged.bindEvent(this.userStateChanged.bind(this));
                //Decide what login method to use
                //console.log("login mode",this.props.app.urlParams.mode);
                const params = this.props.app.urlParams;

                console.log("LOGIN CARD GOT SPECIAL PERAMS",params);

                if(params !== undefined){
                    switch (params.mode) {
                        case "register":
                            this.setState({appView:'create_account'});
                            break;
                        case "forgot_password":
                            this.setState({appView:'forgot_password'});
                            break;
                        case "password_reset_verify":
                            this.setState({appView:'passwordReset'});
                            break;
                        default:
                        this.setState({appView:'login'});
                    }

                }else{
                    this.setState({appView:'login'});

                }

            }
        }else{
            console.log("CORE ISNT CONNECTED YET", this.nextConnectionAttemptIn);
            this.connectRetryMessageTimer = setTimeout(this.backendConnectionTimeout.bind(this), this.nextConnectionAttemptIn);
            this.showRetryMessageTimer = setTimeout(this.showCantConnectMessage.bind(this), 3000);
            this.setState({appView:'app_initialization'});

        }

    }

    showCantConnectMessage(){
        if(this.core.isConnected()){
            return;
        }
        this.setState({appView:'backend_down'});
    }


  backendConnectionTimeout(){
        console.log("Connection attempt timed out, trying again now;");
       this.connectionFailedCounter += 1;
       this.nextConnectionAttemptIn = this.nextConnectionAttemptIn*2;
       console.log("Retrying in",this.nextConnectionAttemptIn);
       console.log("failed attempts ",this.connectionFailedCounter);
       this.check_for_connection();
  }

  //Does nothing, could be used to display something
  loginCallback(user){
      if(this.LDSCore.isUserLoggedIn()){
          console.log("User Logged In");
      }else{
          console.log("User Logged Out");
      }
  }

  userStateChanged(user){
      if(user!=null){
          console.log("User was logged in logincard");
          this.setState({appView:'waiting_for_account_data'});
          //Need to submit an event request for getting the user state
      }else{
          console.log("User was logged out logincard");
          this.setState({appView:'homepage'});
      }
  }

  userUpdatedEvent(data){
    this.processUserData();
  }

  processUserData(){

      console.log("USER DATA MUST HAVE UPATED");

      var user = this.core.getUserData();
      if(user.base == null || user.profile == null){
        this.setState({appView:'waiting_for_account_data'});
        return;
      }

      console.log("Checking user data on login flow");
      console.log(user);

      let user_tos_version = user.base.tos_version;

      let latest_tos_version = this.core.getConfigValue('tos_version');


      if(user_tos_version && user_tos_version == latest_tos_version){
          console.log("TOS Good");
          //Login Complete... now what???
          this.props.app.setNewMainPage('dashboard');
      }else{
          console.log("TOS NEEDS UPDATED");
          this.setState({appView:'tos_required'});
      }

  }

  displayNameSetCallback(result,message){
      console.log("Tos callback 2");
      if(result==true){
          this.processUserData();
      }else{
            this.setState({appView:'error'});

      }
  }

  acceptTosCallback(result,error){
      console.log("Tos callback 2");
      if(result==true){
          this.processUserData();
      }else{
          this.setState({appView:'error'});
      }

  }

  createAccountClicked(){
      this.setState({appView:'create_account'});
  }

  forgotPasswordClicked(){
      this.setState({appView:'forgot_password'});
  }

  selectLoginView(){
      this.setState({appView:'login'});
  }

render() {
    const appView = this.state.appView;

    switch (appView) {
      case "app_initialization":
          return(<LoadingScreen loadingMessage="Connecting To Server"/>);
          break;
      case "create_account":
          return(<CreateAccount LDSCore={this.core} parent={this} loginCallback={this.loginCallback}/>);
          break;
     case "forgot_password":
          return(<ForgotPassword LDSCore={this.core} loginCallback={this.loginCallback} parent={this}/>);
          break;
      case "login":
          return(<Login LDSCore={this.core} loginCallback={this.loginCallback} parent={this}/>);
          break;
      case "passwordReset":
          return(<PasswordReset LDSCore={this.core} parent={this} app={this.props.app}/>);
          break;
      case "backend_down":
          return(<LoadingScreen loadingMessage="Could not connect to LDS Backend Servers! Please try again later!"/>);
          break;
      case "tos_required":
          return(<TermsAndConditions LDSCore={this.core} app={this.props.app} acceptTosCallback={this.acceptTosCallback.bind(this)}/>);
          break;
      case "waiting_for_account_data":
          return(<LoadingScreen loadingMessage="Downloading your profile!"/>);
          break;
      case "error":
          return(<Error loadingMessage="Error: Something went wrong please try again later!"/>);
      default:
          return(<Error loadingMessage="Error: Something went wrong please try again later!"/>);
    }



    }
}

export default LoginCard;
