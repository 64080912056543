import React from 'react';
import GameTitleCard from './GameTitleCard';

class GameStoreRowWidget extends React.Component {

    constructor(props) {
        super(props);
        this.dashboard = this.props.dashboard;
        this.core = this.props.LDSCore;

        this.state = {
            gameCards: [],
            showFailedToLoad: false,
        }

        this.keyItr = 0;

        //Alias
        this.core = this.props.LDSCore;
    }

    componentDidMount() {
        this.refreshStoreGames();
    }

    componentWillUnmount() {
    }

    refreshStoreGames(){
        this.core.getStoreGames(this.storeGamesUpdated.bind(this));
    }

    storeGamesUpdated(data){
        if(!data || !Array.isArray(data)){
            this.setState({showFailedToLoad:true});
            return;
        }

        var gameCards = [];
        for (const game of data) {
            this.keyItr++;
            gameCards.push(<GameTitleCard storeGameEntry={game} dashboard={this.dashboard} LDSCore={this.core} key={this.keyItr+"_store_game_card"}></GameTitleCard>);
        }
        this.setState({gameCards:gameCards});

    }

    render() {
            return(
                <div>
                    <h2 className="text-black mb-0">Games:</h2>
                    <hr className='mt-0'></hr>
                    { this.state.showFailedToLoad ?
                    <div className='alert alert-warning text-black'><i className='mdi mdi-connection me-1'></i><strong>Error: </strong>Failed to download the game list from the server!</div>
                    : null}

                    <div className="grid-cards">
                        {this.state.gameCards}
                    </div>
                </div>
            );
    }
}
export default GameStoreRowWidget;
