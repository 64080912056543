import React from 'react';

class LeftMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    goToHomepage(){

    }

    goToCataclysm(){

    }

    goToInternalContactForm(){

    }

    goToTeams(){

    }

    render() {
      return (
          <ul className="navbar-nav me-auto align-items-center">
              <ul className="navbar-nav me-auto align-items-center">
              <li className="nav-item mx-lg-1">
                  <a className="nav-link active" onClick={this.goToHomepage.bind(this)} href="#">Home</a>
              </li>
              
              <li className="nav-item mx-lg-1">
                  <a className="nav-link" href="#" onClick={this.goToInternalContactForm.bind(this)}>Contact</a>
              </li>
          </ul>
          </ul>

      );
    }
}

export default LeftMenu;
