
import React from 'react';

class ProfileSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayName: '',
            coins: 0,
            premiumCoins: 0,
            pfpUrl: 'https://ldssite.b-cdn.net/avatars/av3.webp',
        };

        this.core = this.props.LDSCore;
    }

    componentDidMount(){
        this.core.events.userUpdated.bindEvent(this.update.bind(this));
        this.update();
    }

    update(){
        var user = this.core.getUserData();
        var nextState = {};

        if(user.base==undefined || user.profile == undefined || user.private == undefined){
            return;
        }

        if(user.base.display_name!=undefined){
            nextState['displayName']=user.base.display_name;
        }
        if(user.private.coins!=undefined){
            nextState['coins']=user.private.coins;
        }
        if(user.private.premium_coins!=undefined){
            nextState['premiumCoins']=user.private.premium_coins;
        }

        if(user.profile.pfp_url!=undefined){
            nextState['pfpUrl']=user.profile.pfp_url;
        }

        this.setState(nextState);
    }

    render() {
      return (

            
            <div className="card bg-info profile-side-card">
                <div className="card-body profile-user-box">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <div className="avatar-lg">
                                        <img src={this.state.pfpUrl} alt="" className="rounded-circle img-thumbnail"></img>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>
                                        <h3 className="mt-1 mb-1 text-white">{this.state.displayName}</h3>

                                        <ul className="mb-0 list-inline text-light">
                                            <li className="list-inline-item me-3">
                                                <h5 className="mb-1 text-white">0</h5>
                                                <p className="mb-0 font-13 text-white">Level</p>
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <h5 className="mb-1 text-white">Bronze</h5>
                                                <p className="mb-0 font-13 text-white">Rank</p>
                                            </li>
                                            <li className="list-inline-item me-3">
                                                <center>
                                                    <h5 className="mb-1 text-white"><img src='https://ldssite.b-cdn.net/images/blue_soul_coin_256.png' style={{width:'30px'}}></img> x {this.state.coins}</h5>
                                                    <p className="mb-0 font-13 text-white">Blue Soul Cores</p>
                                                </center>
                                            </li>
                                            <li className="list-inline-item">
                                                <center>
                                                    <h5 className="mb-1 text-white">
                                                    <img src='https://ldssite.b-cdn.net/images/red_soul_coin_256.png' style={{width:'30px'}}></img> x {this.state.premiumCoins}</h5>
                                                </center>
                                                <p className="mb-0 font-13 text-white">Red Soul Cores</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="text-center mt-sm-0 mt-3 text-sm-end">
                                <button type="button" className="btn btn-light">
                                    <i className="mdi mdi-account-edit me-1"></i> Edit Profile
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


      );
    }
}

export default ProfileSummary;

