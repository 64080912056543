import React from 'react';

import { config } from "../../../../config";
import CataclysmSignup from "../components/cataclysm/CataclysmSignup"
import Footer from '../../both/Footer';
import Cataclysm from './cataclysm_pages/Cataclysm';
import CataclysmSettings from './cataclysm_pages/CataclysmSettings';


class CataclysmSwitcher extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
        this.parent = this.props.parent;


        this.state = {
            page: 'signup',
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    setPage(page){
        this.setState({page:page});
    }


    render() {
            switch (this.state.page) {
                case 'signup':
                        return(<Cataclysm LDSCore={this.core} parent={this}></Cataclysm>)
                        break;
                    case 'settings':
                        return(<CataclysmSettings LDSCore={this.core} parent={this}></CataclysmSettings>)
                        break;
            
                default:
                    break;
            }
    }
}
export default CataclysmSwitcher;
