
import Verification from "../../helpers/Verification.js";
import Dexie from 'dexie';


export default class Library{


    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;
        this.webRequest = webRequest;

        //Create Events
        this.events.createEvent('userItemLibraryUpdated');
        this.events.createEvent('userGameLibraryUpdated');

        this.db = new Dexie('LDS_Library');
        this.db.version(1).stores({
          your_games: '++id, game_name, uuid',
          your_items: '++id, item_name, uuid',
          your_products: '++id, product_name, uuid',
        });

        this.events.preemptiveBind('cacheCleared',this.clearLibraryCache.bind(this));
    }

    /* Routes
    library/get_games
    library/get_items
    */
    clearLibraryCache(){
        console.log("Clearing Library Cache");
        this.db.delete();
    }

    //Get the store games from the cache or request an update
    usersGameLibrary(callback){
        //Todo get from cache or download  
        timers.requestUsersGameLibrary(callback);

    }

    //Request the items from the backend
    requestUsersGameLibrary(callback){
        this.webRequest.basic('library/get_games',this.usersGameLibraryDownloadCallback.bind(this,callback),null,null);
    }

    //process a game download request 
    usersGameLibraryDownloadCallback(callback,res){
        console.log("Game Library Download Res:",res);
    }

    //Get the store items from the cache or request an update
    getUsersItemLibrary(callback){
        //TODO get from cache or download
        this.requestUsersItemLibrary(callback);
    }

    //Request the items from the backend
    requestUsersItemLibrary(){
        this.webRequest.basic('library/get_items',this.usersItemLibraryDownloadCallback.bind(this,callback),null,null);
    }

    //process an item download request 
    usersItemLibraryDownloadCallback(callback,res){
        console.log("Item Library Download Res:",res);
    }

    isProductInUsersLibrary(type,uuid){
        //TODO implement
        return true;
    }

}
