
import Verification from "../../helpers/Verification.js";

export default class Credentials{

    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;

        this.webRequest = webRequest;

        this.credentialsRequested = false;
        this.userCredentials = null;

        //Create events
        this.events.createEvent('credentialsUpdated');


    }

    connect(){
        //Make initial connections
        //setTimeout(this.downloadUsersCredentials.bind(this),2000);
    }

    downloadUsersCredentials(){
        this.credentialsRequested = true;
        this.webRequest.basic('credentials/get_all',this.downloadUsersCredentialsCallback.bind(this),null,null);
    }

    downloadUsersCredentialsCallback(res){
        if(res!=undefined && res.status=='success'){
            this.userCredentials = res.data;
            //TODO see if data changed
            this.events.credentialsUpdated.trigger(true);
        }

    }

    getCredential(serviceName){
        console.log("LOOKING FOR",serviceName,'in',this.userCredentials);
        var targetCred = null;

        if(!Array.isArray(this.userCredentials)){
            console.log("No Creds");
            if(!this.credentialsRequested){
                this.downloadUsersCredentials();
            }
            return null;
        }

        for (const credRow of this.userCredentials) {
            if(credRow.service_name!=undefined && credRow.service_name==serviceName){
                targetCred = credRow;
                break;
            }
        }
        console.log("FOUND CRED",targetCred);
        return targetCred;
    }
   
    get3rdPartyLink(serviceName,callback){
        var parms = {};

        console.log("REQUESTING LINK FOR SERVICE: ",serviceName);

        var route = null;
        switch (serviceName) {
            case 'twitch_basic': route = 'third_party_auth/twitch/redirect'; parms.level = 'basic'; break;
            case 'twitch_advanced': route = 'third_party_auth/twitch/redirect'; parms.level = 'advanced'; break;
            case 'twitch_mod': route = 'multiplayer_services/auth_mod_advanced_redirect'; parms.level = 'mod'; break;
            case 'youtube': route = 'third_party_auth/youtube/redirect'; parms.level = 'basic'; break;
            case 'stream_elements': route = 'third_party_auth/stream_elements/redirect'; parms.level = 'basic'; break;
        
            default:
                console.log("Unknown Service",serviceName);
                return;
                break;
        }

        if(route==null){
            return;
        }

        this.webRequest.basic(route,this.get3rdPartyLinkCallback.bind(this,callback),parms,null);
    }

    get3rdPartyLinkCallback(callback,res){
        console.log("GOT 3rd party res,",res);

        if(res!= undefined && res.status=='success'){
            if(res.data!=undefined && res.data.url!=undefined)
            callback(res.data);
            return;
        }
        
        callback(null);
    }

    saveThirdPartyAuthAttempt(callback){
        var attempt = localStorage.getItem('auth_attempt');
        localStorage.removeItem('auth_attempt');


        if(attempt==undefined){
            callback({status:'error',message:'Didn\'t recieve any credentials'});
        }

        var params = JSON.parse(attempt);

        if(params==undefined || params.type==undefined){
            console.log("Failed, missing params");
            return;
        }

        console.log("auth request type ",params.type);


        var route = null;
        switch (params.type) {
            case '/twitch_auth_basic': route = 'third_party_auth/twitch/callback'; break;
            case '/twitch_auth_advanced': route = 'third_party_auth/twitch_advanced/callback'; break;
            case '/twitch_mod_auth_advanced': route = 'multiplayer_services/auth_mod_advanced_callback'; break;
            case '/youtube_auth': route = 'third_party_auth/youtube/callback'; break;
            case '/stream_elements_auth': route = 'third_party_auth/stream_elements/callback'; break;
        }

        if(route==null){
            callback({status:'error',message:'Invalid Auth Type'});
        }

        this.webRequest.basic(route,this.saveThirdPartyAuthAttemptCallback.bind(this,callback),params,null);
    }

    saveThirdPartyAuthAttemptCallback(callback,res){
        console.log("GOT TPCB Res",res);
        if(res!=undefined){
            //Update credentials list
            this.downloadUsersCredentials();

            callback(res);
            return;
        }
        callback({status:error,message:'no response'});
    }

    deleteCredential(uuid,callback){
        var params = {uuid:uuid};
        this.webRequest.basic('credentials/delete',this.deleteCredentialCallback.bind(this,callback),params,null);
    }

    deleteCredentialCallback(callback,res){
        //Update credentials list
        this.downloadUsersCredentials();

        if(res!=undefined && res.status=='success'){
            callback(true);
            return;
        }

        callback(false);

    }

    updateCredential(newCred){
        console.log("Attempting to update stored credential!",newCred);
        this.webRequest.basic('multiplayer_services/update_third_party_credential',this.updateCredentialsCallback.bind(this,null,newCred),newCred,null);
    }

    updateCredentialsCallback(callbackisnull,newCred,res){
        //TODO retry on failure
        console.log("UPDATE CRED RES",res,newCred);
        if(res && res.status){
            if(res.status=='success'){
                console.log("Credential Updated",newCred.uuid);
            }else{
                console.log("Failed to update backend with refreshed credential, retrying in 10 second");
                setTimeout(this.updateCredential.bind(this,newCred),10000);
            }
        }else{
            console.log("Status was not set on request message");
        }
    }
}
