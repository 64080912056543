import React from 'react';
import LinkedAccountBase from './LinkedAccountBase';

class LinkSteam extends LinkedAccountBase {

    constructor(props) {
        super(props);



       
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {

        if(this.props.disabled != undefined){
            return( <div className="alert alert-danger bg-secondary-lighten text-black"> Steam Integration (Disabled)(Coming Soon)</div>);
        }

        return(
            <div className="card ribbon-box">
                <div className="card-body">

                    <h5 className="text-primary float-start mt-0">Steam</h5>
                    <div className="ribbon-content">
                        <b>Link your Steam account to verify your Steam identiy and access all of your items.</b>
                        <p className="mt-1">
                            Linked Accounts:
                            <ul>
                                <li>Have a shared item inventory</li>
                                <li>Use steam for public profile information</li>
                            </ul>
                        </p>
                        <div className="btn btn-info">Link Your Steam Account</div>
                    </div>
                </div> 
            </div>

            
        );
    }
}
export default LinkSteam;
