import React from 'react';
import LinkedAccountBase from './LinkedAccountBase';

class LinkStreamElements extends LinkedAccountBase {

    constructor(props) {
        super(props);

        this.serviceName = 'stream_elements';
        this.setChildRef(this);
       console.log("Reconstructed");
    }

    componentDidMount() {
        this.requestCredential();
    }

    componentWillUnmount() {
        console.log("Mounted");
    }

    componentWillReceiveProps(nextProps) {//Lifecycle method to get the updated props
        this.setState({ 
            linkInProgress: nextProps.linkInProgress,
            error:nextProps.error,
            errorMessage:nextProps.errorMessage,
            linkSuccess:nextProps.linkSuccess,
            credential: nextProps.credential,
         });
    }

    setParentRef(ref){
        this.parent = ref;
    }

    componentDidUpdate(){
        console.log("CHILD UPDATED");
    }

    render() {

            if(this.props.disabled != undefined){
                return( <div className="alert alert-danger bg-secondary-lighten text-black"> Stream Elements Disabled</div>);
            }

            return(
                <div className="card ribbon-box">
                    <div className={this.state.linkSuccess ? "card-body bg-success-lighten border border-success" : "card-body"}>
                        {this.state.error ? <div className="alert alert-danger bg-danger text-white" ><b> Error: {this.state.errorMessage}</b></div>: null}
                        {this.state.linkInProgress ? 
                        <div className="alert alert-danger bg-warning text-black" >
                            <div className="spinner-border spinner-border-sm text-black me-2" role="status"></div>
                            <b>Waiting for credentials: Please do not close this page!</b>
                        </div>: null}

                        <div className="ribbon ribbon-info float-end"><i className="mdi mdi-lightning-bolt me-1"></i>Required to Join A Cataclysm Server</div>

                        {this.state.linkSuccess ? 
                        <h4 className="text-black float-start mt-0"><b className="text-black"><i className="mdi mdi-check-bold text-black"></i> Stream Elements Account Linked!</b></h4>
                        : 
                        <h4 className="text-primary float-start mt-0">Stream Elements</h4>
                        }

                        
                        <div className="ribbon-content">

                            <b>Link your Stream Elements account to verify your idenity and give us access to your donation feed.</b>
                            <p className="mt-1">
                                Linked Accounts:
                                <ul>
                                    <li>Can use viewer donations to interact with the game</li>
                                </ul>
                            </p>

                            {!this.state.linkSuccess ? <div className="btn btn-info" onClick={this.get3rdPartyLink.bind(this)}>Link Your Stream Elements Account</div>: null}
                            {this.state.credential!=null ? <div className="btn btn-danger bg-danger text-black" onClick={this.unlinkService.bind(this)}><b><i className="mdi mdi-trash-can-outline pe-2"></i>Unlink Your Stream Elements Account</b></div>: null}
                            
                        </div>
                    </div> 
                </div>

               
            );
    }
}
export default LinkStreamElements;
