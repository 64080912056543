
import Verification from "../../helpers/Verification.js";
import Dexie from 'dexie';

export default class Store{


    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;
        this.webRequest = webRequest;

        //Create Events
        this.events.createEvent('gamesListUpdated');

        /*Routes:
        store/get_games
        store/get_items
        store/get_products
        */

        this.db = new Dexie('LDS_Store');
        this.db.version(2).stores({
          store_games: 'uuid, game_name',
          store_items: 'uuid, item_name',
          store_products: 'uuid, product_name',
        });

        this.events.preemptiveBind('cacheCleared',this.clearStoreCache.bind(this));

        //Products
        this.games = new Map();
        this.products = new Map();
        this.items = new Map();
        
    }

    restoreFromCache(){
        //TODO get the cached items, games, and products and set them

    }

    clearStoreCache(){
        console.log("Clearing Store Cache");
        this.db.delete();
    }

    //Get the store games from the cache or request an update
    getStoreGames(callback){
        if(!this.games.size){
            callback(this.games);
        }
        this.requestStoreGames(callback);
    }

    //Request the items from the backend
    requestStoreGames(callback){
        this.webRequest.basic('store/get_games',this.gameDownloadCallback.bind(this,callback),null,null);
    }

    //process a game download request 
    gameDownloadCallback(callback,res){
        if(res && res.status=='success'){
            console.log("Game Download Res:",res);
            if(res.data){
                callback(res.data);
                this.updateCacheWithNewData(res.data,'games');
                return;
            }
        }
        callback(null);
    }

    //Get the store items from the cache or request an update
    getStoreItems(callback){
        if(!this.items.size){
            callback(this.items);
        }
        this.requestStoreItems(callback);
    }

    //Request the items from the backend
    requestStoreItems(callback){
        this.webRequest.basic('store/get_items',this.itemDownloadCallback.bind(this,callback),null,null);
    }

    //process an item download request 
    itemDownloadCallback(callback,res){
        if(res && res.status=='success'){
            console.log("Item Download Res:",res);
            if(res.data){
                callback(res.data);
                this.updateCacheWithNewData(res.data,'items');
                return;
            }
        }
        callback(null);

    }

    //Get the store products from the cache or request an update
    getStoreProducts(callback){
        if(!this.products.size){
            callback(this.products);
        }
        this.requestStoreProducts(callback);
    }

    //Request the products from the backend
    requestStoreProducts(callback){
        this.webRequest.basic('store/get_products',this.productDownloadCallback.bind(this,callback),null,null);
    }

    //process a product download request 
    productDownloadCallback(callback,res){
        if(res && res.status=='success'){
            console.log("Product Download Res:",res);
            if(res.data){
                callback(res.data);
                this.updateCacheWithNewData(res.data,'products');
                return;
            }
        }
        callback(null);
    }

    //Type can be games,items,products. Data is an array of that type
    updateCacheWithNewData(data,type){
        var table = null;
        var map = null;
        switch (type) {
            case 'games': 
                table=this.db.store_games;
                map = this.games;
                break;
            case 'items': 
                table=this.db.store_items; 
                map = this.items;
                break;
            case 'products': 
                table=this.db.store_products; 
                map = this.products;
                break;
        }
        if(!table){
            return;
        }

        for (const newItem of data) {
            table.put(newItem);
            map.set(newItem.uuid,newItem);
        }
    }

    getStoreGame(uuid){
        return this.games.get(uuid);
    }

    getStoreItem(uuid){
        return this.items.get(uuid);
    }

    getStoreProduct(uuid){
        return this.products.get(uuid);
    }

    

}
