
import {WebRequest} from "../helpers/WebRequest.js"
import User from "./components/User.js"
import Auth from "./components/Auth.js"
import GlobalConfig from "./components/GlobalConfig.js"
import Credentials from "./components/Credentials.js"
import Cataclysm from "./components/Cataclysm.js"
import MultiplayerServices from "./components/MultiplayerServices.js"
import isDev from "../helpers/DetectDev.js"
import isNode from "../helpers/DetectNode.js"
import Store from "./components/Store.js"
import Library from "./components/Library.js"
import AppManagement from "./components/AppManagement.js"

export default class LDSBackend{

    constructor(config,eventSystem, httpsLibrary=null){
        this.config = config;
        this.events = eventSystem;

        if(isNode()){
            console.log("NODE ENV DETECTED");
        }
        if(isDev()){
            console.log("DEV ENV DETECTED");
        }

        this.webRequest = new WebRequest(config,httpsLibrary);

        //Create events
        this.events.createEvent('connectionChanged');

        //bind event
        this.events.preemptiveBind('connectionChanged',this.connectionChanged.bind(this))

        //App Management - NOTE: bind other components to cacheClearned event
        this.appManagement = new AppManagement(config,eventSystem,this.webRequest);

        //Components
        this.user = new User(config,eventSystem,this.webRequest);
        this.auth = new Auth(config,eventSystem,this.webRequest);
        this.globalConfig = new GlobalConfig(config,eventSystem,this.webRequest);
        this.credentials = new Credentials(config,eventSystem,this.webRequest);
        this.cataclysm = new Cataclysm(config,eventSystem,this.webRequest);
        this.multiplayerServics = new MultiplayerServices(config,eventSystem,this.webRequest);
        this.store = new Store(config,eventSystem,this.webRequest);
        this.library = new Library(config,eventSystem,this.webRequest);

        this.connected = false;

        this.authToken = null;

        
    }

    connect(){
        this.initializeConnection();
        this.auth.connect();
        this.user.connect();
        this.globalConfig.connect();
        this.credentials.connect();
        this.cataclysm.connect();
    }

    initializeConnection(){
        this.authToken = localStorage.getItem('_token');
        if(this.authToken){
            this.auth.signInWithSanctumToken(this.authToken);
        }else{
            this.pingBackend();
        }
   }

   connectionChanged(connected){
    console.log("Connection Changed Event, New connection status:",connected)
    this.connected = connected;
   }

   isConnected(){
     return this.connected;
   }

   pingBackend(){
        this.webRequest.basic('ping',this.pingBackendCallback.bind(this),null,null);
   }

   pingBackendCallback(res){
        if(res.status = 'success'){
            this.connected = true;
            console.log("Connected to server!!!")
            this.events.connectionChanged.trigger(this.connected);
            return;
        }
        this.connected = false;
        this.events.connectionChanged.trigger(this.connected);
   }  

   fetchGlobalConfig(callback){
      //TODO get global config... not sure what this is used for anymore
   }


}
