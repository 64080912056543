import React from 'react';

import { config } from "../../../../../config";
import CataclysmSignup from "../../components/cataclysm/CataclysmSignup"
import Footer from '../../../both/Footer';


class Cataclysm extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
        this.myRef = React.createRef();
        this.parent = this.props.parent;

        this.core.events.cataclysmUpdated.bindEvent(this.cataclysmUpdatedEvent.bind(this));
        this.core.downloadCataclysmData();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    cataclysmUpdatedEvent(data){

    }

    scrollToStreamer(){
        this.myRef.current.scrollIntoView();
    }

    openSettings(){
        this.parent.setPage('settings');

    }

    render() {
            return(
                <div>
                    <div className='content'>

                        <div className=" w-100 mt-0 pt-0" style={{height:'400px',backgroundImage:`url(${config.paths.cdn_base+"images/background.jpg"})`}}>
                            <div className="row">
                                <div className="card-body ms-5">
                                    <div className="">
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <h1 className="text-white">Join The Cataclysm</h1>
                                        <p className="text-white">
                                            <h4>Join forces with your favorite streamer and help them stop the apocalypse.</h4>
                                            <br></br>
                                            <div className="btn btn-success">Players & Viewers</div>
                                            <div className="btn btn-warning ms-2" onClick={this.scrollToStreamer.bind(this)}>Streamers</div>
                                            <div className="btn btn-secondary ms-2" onClick={this.openSettings.bind(this)}>Settings</div>
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>

                        <div className='container-fluid text-black'>
                            <br></br>

                           
                            <div className="row py-4" ref={this.myRef}>
                                <div className="col-lg-12">
                                    <div className="text-center">
                                        <h1 className="mt-0"><i className="mdi mdi-infinity"></i></h1>
                                        <h2 className="text-danger">The Cataclysm For Streamers:</h2>
                                        <p className="text-muted mt-2">
                                            Invite your stream to join you in your own interactive slice of the multiverse. Stop the apocalypse before the world ends or pay the price...    
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <center><h4 className="text-primary">Requirements:</h4></center>
                                            <hr></hr>
                                            <ul>
                                                <li>Sign Up For Cataclysm (It's Free!)</li>
                                                <li>Twitch Affiliate or Partner</li>
                                                <li>Link Your Twitch Account</li>
                                                <li>Link Your Donation Account</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <center><h4 className="text-primary">Features:</h4></center>
                                            <hr></hr>
                                            <ul>
                                                <li>Your Own Cataclysm Dedicated Server (1-1000 players)</li>
                                                <li>Cataclysm Game Mode</li>
                                                <li>"The Ban Game"</li>
                                                <li>Voice Chat</li>
                                                <li>Twitch & Donation Integraion</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <center><h4 className="text-primary">Community:</h4></center>
                                            <hr></hr>
                                            Hang out at the sandbar and give back to your community with interactive events for donations, subs, and bits.. But don't get too comfortable the world is ending, do you have what it takes to stop the apocalypse?
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <CataclysmSignup LDSCore={this.core} />




                            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h1 className="mt-0"><i className="mdi mdi-frequently-asked-questions"></i></h1>
                            <h3>Frequently Asked <span className="text-primary">Questions</span></h3>
                            <p className="text-muted mt-2">Feel free to reach out if your question isn't answered below:</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-5 offset-lg-1">
                        <div>
                            <div className="faq-question-q-box">Q.</div>
                            <h4 className="faq-question text-body">Why do you have to be a Twitch Affiliate or Partner to signup?</h4>
                            <p className="faq-answer mb-4 pb-1 text-muted">
                                Currently, we're only acceptecting Twitch Partners and Affiliates for Cataclysm signups. The Cataclysm requires a dedicated server instance for each streamer... This gets expensive. We're planning on offering a p2p and single player version soon! 
                            </p>
                        </div>

                       
                    </div>

                    <div className="col-lg-5">
                        <div>
                            <div className="faq-question-q-box">Q.</div>
                            <h4 className="faq-question text-body">How do I fix _______?</h4>
                            <p className="faq-answer mb-4 pb-1 text-muted">Well this is awkward.. Send us a direct message.</p>
                        </div>

                     
                    </div>
                </div>

            </div>



                        </div>
                    </div>
                    <Footer></Footer>

                </div>
            );
    }
}
export default Cataclysm;
