
import Verification from "./../../helpers/Verification.js";

export default class Auth{

    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;

        this.webRequest = webRequest;

        this.userAccount = {
            missingAccountData: [],
            attributes: [],
            lastUpdate: null,
        };

        //Create events
        this.events.createEvent('authChanged');

        this.hasAuth = false;

    }

    connect(){
        //Make initial connections
    }

    signInWithEmailAndPassword(email,password,callback){
        console.log("ATTEMPTING EMAIL SIGNIN",email,password);
        //TODO sign in to backend with email and password
        var parms = {email:email,pass:password};
        this.webRequest.basic('login',this.signInCallback.bind(this,callback),parms,null);
 
        //TODO store token recived on login
    }

    signInCallback(callback,res){
        console.log("Login attempt:",res);
        if(res==undefined){
            callback(false,'Error: Couldn\'t login!');
        }

        if(res.status=='success'){

            //Save auth token
            if(res.data!=undefined && res.data.token != undefined){
                console.log("GOT AUTH TOKEN!!!",res.data.token);
                localStorage.setItem('_token', res.data.token);
            }
            
            this.events.authChanged.trigger(true);
            callback(true,'Login Successful');
            return;
        }
        
        //Otherwise error
        callback(false,res.message);        
    }
  
    signInWithSanctumToken(token,callback){
        var token = localStorage.getItem('_token');
        if(token!=undefined){
            var parms = {_token:token};
            this.webRequest.basic('check_auth',this.signInWithSanctumTokenCallback.bind(this,callback),parms,null);
        }
    }
 
    signInWithSanctumTokenCallback(callback,res){
         if(res && res.status=='success'){
             this.hasAuth = true;
             console.log("HAVE AUTH HAVE CONNECTION");
             this.events.connectionChanged.trigger(true);
             this.events.authChanged.trigger(true);
             return;
         }

         //Connection but no auth
         if(!res || res.status=='error'){
            this.events.connectionChanged.trigger(true);
            //TODO can I delete this instead of null?
            localStorage.removeItem('_token');
         }

         //Fail condition
         this.hasAuth = false;
         console.log("GOT RES IN AUTH REQUEST CALLBACK",res,callback);
    }
 
    signOut(callback){
        //Sign out and call backend with true/false if successful
        this.webRequest.basic('logout',this.logoutCallback.bind(this,callback),null,null);
    }

    logoutCallback(callback,res){
        if(res!=undefined && res.status=='success'){
            this.user = null;
            this.hasAuth = false;
            callback(true);
            return;
        }
        //At least sign out of browser
        this.user = null;
        this.hasAuth = false;
        callback(false);
    }

    createAccount(displayName,email,password,callback){
        console.log("AUTH CREATE ACCOUNT");
        var parms = {display_name:displayName,email:email,pass:password};
        this.webRequest.basic('create_account',this.createAccountCallback.bind(this,callback),parms,null);
    }

    createAccountCallback(callback,res){
        if(res==undefined){
            callback(false,'Failed to create a new account!');
            return;
        }

        if(res.status=='error'){
            callback(false,res.message);
            return;
        }

        if(res.status == 'success'){
            if(res.data!=undefined && res.data.token!=undefined){
                localStorage.setItem('_token', res.data.token);
                this.events.authChanged.trigger(true);
                return;
            }
        }

        callback(false,'Failed (Unknown Reason)');

    }
  
    isUserLoggedIn(){
        return this.hasAuth;
    }


    requestPasswordReset(email,callback){
        console.log("requesting Password reset email",email);
        var parms = {email:email};
        this.webRequest.basic('request_password_reset',this.requestPasswordResetCallback.bind(this,callback),parms,null);
   }

   requestPasswordResetCallback(callback,res){
        if(res==undefined){
            callback(false,'Failed to send a password reset request!');
            return;
        }

        if(res.status=='error'){
            callback(false,res.message);
            return;
        }

        if(res.status == 'success'){
            callback(true,'Success!');
            return;
        }

        callback(false,'Failed (Unknown Reason)');
   }

   verifyPasswordReset(token,newPassword,callback){
        console.log("Submitting Password update request:",token,newPassword);
        var parms = {token:token,new_password:newPassword};
        this.webRequest.basic('verify_password_reset',this.verifyPasswordResetCallback.bind(this,callback),parms,null);
   }

   verifyPasswordResetCallback(callback,res){
    if(res==undefined){
        callback(false,'Failed to update your password!');
        return;
    }

    if(res.status=='error'){
        callback(false,res.message);
        return;
    }

    if(res.status == 'success'){
        callback(true,'Success!');
        return;
    }

    callback(false,'Failed (Unknown Reason)');
   }

   setUserTOSVersion(tos_version,callback){
    //TODO set the users signed tos version
    }


}
