import React from 'react';

class RightMenuLoggedOut extends React.Component {

    constructor(props) {
        super(props);

        this.loginClickedRef = this.loginClicked.bind(this);
        this.createAnAccountClickedRef = this.createAnAccountClicked.bind(this);



    }

    componentDidMount() {

            document.getElementById("create_account_button").hidden = false;
    }

    loginClicked(){
        this.props.app.setNewMainPage("login");
    }

    createAnAccountClicked(){
        this.props.app.setNewMainPage("login");
    }

    render() {
      return (
          <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item me-0">


                <div className="inline-float">
                  <button onClick={this.loginClickedRef} className="btn btn-sm btn-success border-dark btn-rounded d-none d-lg-inline-flex">
                      <i className="mdi mdi-account-circle me-2"></i> Login
                  </button>
                  </div>
                  <div id="create_account_button" className="inline-float" hidden>
                      <button onClick={this.createAnAccountClickedRef} className="btn btn-sm btn-light border-dark  btn-rounded d-none d-lg-inline-flex">
                          <i className="mdi mdi-account-circle me-2"></i> Create An Account
                      </button>
                  </div>
              

              </li>
          </ul>
      );
    }
}

export default RightMenuLoggedOut;
