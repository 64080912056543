import semverGreaterThan from "../../helpers/VersionComapre";
import packageJson from './../../../../../package.json';


export default class AppManagement{

    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;

        this.webRequest = webRequest;

        this.userAccount = {
            missingAccountData: [],
            attributes: [],
            lastUpdate: null,
        };

        this.events.createEvent('cacheCleared');
        

        //Check version on startup
        this.checkAppVersion();

        //Check for app update every 15 min - 900000ms = 15min
        setInterval(this.checkAppVersion.bind(this),900000);

    }

    checkAppVersion(){
        this.webRequest.custom('/meta.json',this.processAppVersionData.bind(this),null,null);
    }

    processAppVersionData(res){
        if(!res || res.status!=200 || !res.data){
            return;
        }

        try {
            var meta = res.data;

            const latestVersion = meta.version;
            const currentVersion = packageJson.version;
    
            //Check if this is the first version ever loaded
            if(!localStorage.getItem("last_version")){
            this.updateLatestVersion();
            }
    
            const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
            if (shouldForceRefresh) {
                if(localStorage.getItem("last_cache_clear_and_reset")==null || localStorage.getItem("last_cache_clear_and_reset") < new Date() / 1000){
                    console.log("BUSTING CACHE");
                    this.clearCacheResetApp();
                }else{
                    console.log("TOO SOON TO CACHE BUST");
                }
                }else{
                console.log("App Up To Date");
            }
        } catch (error) {
            console.log("Failed to process meta.json",error);
        }
    }

    updateLatestVersion(){
        //Allow cache busting every 15min
        localStorage.setItem("last_cache_clear_and_reset", (new Date() / 1000)+900);
        localStorage.setItem("last_version",packageJson.version);
    }

    clearCacheResetApp(){
        this.updateLatestVersion();
        this.events.cacheCleared.trigger(true);
        setTimeout(this.delayedReload.bind(this),200);
            
    }

    delayedReload(){
        window.location.reload(true);
    }

}

