
import React from 'react';

class Breadcrumb extends React.Component {

    constructor(props) {
        super(props);
        this.style = 'dark';
        if(this.props.style){
            this.style = this.props.style;
        }

        this.state = {
        };
    }

    render() {

        var pageTreeArr=[];
        for (const [key,value] of Object.entries(this.props.pageTree)) {
            pageTreeArr.push(<li className="breadcrumb-item"><a href="#">{key}</a></li>);
        }

      return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            {pageTreeArr}
                        </ol>
                    </div>
                    {this.style == 'dark' ?
                        <h4 className="page-title text-black">{this.props.title}</h4>
                    :
                        <h4 className="page-title text-white">{this.props.title}</h4>
                    }
                </div>
            </div>
        </div>

      );
    }
}

export default Breadcrumb;

