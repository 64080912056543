import React from 'react';

import { config } from '../../../config'; 

class HeroSection extends React.Component {

    constructor(props) {
        super(props);

        this.app = this.props.app;

        this.state = {
           
        };



    }

    
    login(){
        this.app.setNewMainPage('login',true,'dashboard');
    }

    render() {
      return (
          <section className="hero-section ">
              <div className="container hero_trailer_fix hero_container_width">
              
                  <div className="row align-items-center hero_trailer_fix">
                      <div className="col-md-5">
                          <div className="mt-md-4">


                            <img src={config.paths.cdn_base+'/images/last_day_to_party_logo.png'}></img>

                                <div className="row text-center">
                                    <div className="col">
                                    <div className="badge badge-success-lighten rounded-pill">FREE</div><div className="badge badge-warning-lighten rounded-pill">Alpha 1.0.0</div>
                                    </div>
                                </div>

                              <p className="mb-4 font-16 text-white-50 mt-1">The world is ending… It has been for a while. 
                              Can you uncover the secret that the apes and robots are hiding? Can you solve the ancient mystery and save the world before it’s too late?
                                </p>

                                <div className="row text-center">
                                    <a href="#" onClick={this.login.bind(this)} className="btn btn-warning btn-lg text-dark fw-bold">Play Now! <i className="mdi mdi-arrow-right ms-1"></i></a>

                                </div>
                          </div>
                      </div>
     
                  </div>
              </div>
              <div className="trailer_video_container">
              <img className="hero_gradient" src={config.paths.cdn_base+'/images/hero_gradient.png'}></img>
              <video className="hero_trailer" poster={config.paths.cdn_base+'/images/trailer_poster.jpg'} autoPlay muted loop>
                    <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.webm'} type="video/webm"/>
                    <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.mp4'} type="video/mp4"/>
                </video>
             </div>
          </section>

      );
    }
}

export default HeroSection;
