import React from 'react';

import RightMenuLoggedOut from './RightMenuLoggedOut'
import RightMenuLoggedIn from './RightMenuLoggedIn'



class RightMenu extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;

        this.loggedin = false;
        this.userStateChangedEventID = null;
    }

    componentDidMount() {
        this._ismounted = true;
        this.userStateChangedEventID = this.core.events.authChanged.bindEvent(this.userStateChanged.bind(this));
    }

    componentWillUnmount() {
        this._ismounted = false;
        this.core.events.authChanged.unbindEvent(this.userStateChangedEventID);
    }

    userStateChanged(user){
      if(user!=null){

          this.loggedin = true;
      }else{
          this.loggedin = false;
      }

      if(this._ismounted){
          this.forceUpdate();
      }

    }

    render() {
        if(this.loggedin){
            return(
                <div>
                    <div id="right-menu-loggedin">
                        <RightMenuLoggedIn app={this.props.app} />
                    </div>
                </div>
            );
        }else{
            return(
                <div>
                    <div id="right-menu-loggedout">
                        <RightMenuLoggedOut app={this.props.app} LDSCore={this.props.LDSCore}/>
                    </div>
                </div>
            );
        }
    }
}

export default RightMenu;
