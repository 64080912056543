import React from 'react';
import { config } from '../../../config'; 
import FooterContent from './component/FooterContent'


class FooterStore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      return (
          <div className="container ps-3 pe-3">
            <FooterContent ></FooterContent>
          </div>
      );
    }
}

export default FooterStore;
