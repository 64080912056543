import React from 'react';
import { config } from '../../../config'; 
import FooterContent from './component/FooterContent'


class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      return (
        <div>
            <FooterContent ></FooterContent>
        </div>
      );
    }
}

export default Footer;
