import React from 'react';

import NavBar from './homepage_view/NavBar'
import HeroSection from './homepage_view/HeroSection'
import Services from './homepage_view/Services'
import ImageFeatures from './homepage_view/ImageFeatures'
import Team from './homepage_view/Team'
import Cataclysm from './homepage_view/Cataclysm'
import Contact from './homepage_view/Contact'
import Footer from './both/Footer'


class Homepage extends React.Component {

    componentDidMount() {

    }

    constructor(props) {
        super(props);

        this.app = this.props.app;
    }

    render() {
      return (
          <div className="headerbackground ">
            <div className="bodybg"></div>
            <NavBar LDSCore={ this.props.LDSCore } app={this.props.app} />
            <HeroSection app={this.app} />
            <Services />
            <Cataclysm  app={this.props.app} LDSCore={ this.props.LDSCore } />
            <Team />
            <Footer />
        </div>
      );
        }
    }

export default Homepage;
