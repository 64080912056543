import BasicEvent from "./BasicEvent.js"
import uuidv4 from "./UUIDV4.js";

export default class EventManager{

  constructor(){
    this.preemptiveEvents = new Map();
          
        }

  createEvent(eventName){
      var newEvent = new BasicEvent(eventName);
      this[eventName] = newEvent;

      //Check if there are preemptive bindings
      if(this.preemptiveEvents.has(eventName)){
        const pendingEvents = this.preemptiveEvents.get(eventName);
        for (const event of pendingEvents) {
          newEvent.preemptiveBindEvent(event.uuid,event.callback);
        }
        //Delete preemptive event entry after binding
        this.preemptiveEvents.delete(eventName);
      }
  }

  destroyEvent(eventName){
    this.eventName.unbindAll();
    delete this[eventName];
  }

  //Bind events before they even exist
  preemptiveBind(eventName,callback){

    if(this.hasOwnProperty(eventName)){
      this[eventName].bindEvent(callback);
      return;
    }

    var uuid = uuidv4();
    if(this.preemptiveEvents.has(eventName)){
      var pendingEvents = this.preemptiveEvents.get(eventName);
      pendingEvents.push({uuid:uuid,callback:callback});
      //Save
      this.preemptiveEvents.set(eventName,pendingEvents);
    }else{
      this.preemptiveEvents.set(eventName,[{uuid:uuid,callback:callback}]);
    }
  }

}
