
import React from 'react';
import ItemCardSmall from './store/ItemCardSmall';

class YourItems extends React.Component {

    constructor(props) {
        super(props);
        this.dashboard = this.props.dashboard;

        this.state = {
            itemCards: [],
        };

    }

    componentDidMount(){
        this.buildItemCards();
    }

    buildItemCards(){
        var cards = [];
        for (let index = 0; index < 3; index++) {
            var coverImage = 'https://ldssite.b-cdn.net/images/games/ldtp/items/empty_item_sm.png';
            var uuid = '1lkasdjf19=3kasdnfasdf=1asddnfasd';
            cards.push(<ItemCardSmall coverImage={coverImage} dashboard={this.dashboard} productType='item' productId={uuid} />);
        }
        var blankImage = 'https://ldssite.b-cdn.net/images/games/ldtp/items/empty_item_sm.png';
        cards.push(<ItemCardSmall coverImage={coverImage} dashboard={this.dashboard} productType='item' productId='empty' ribbonColor="ribbon-info" ribbonText="Visit The Store" />);
       
        this.setState({itemCards:cards});
    }

    render() {
      return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                   <h1 className='text-dark mb-0'>Your Items:</h1>
                </div> 
            </div>
            <hr className='mt-0'></hr>
            <div className="item-grid-cards-sm overflow-visible">

                {this.state.itemCards}
                    

            </div>
        </div>
      );
    }
}

export default YourItems;

