export const config = {
    devEnv: false,
    paths: {
        cdn_base: "https://ldssite.b-cdn.net/",
        baseUrl: "https://lastdaystudios.com",
        baseUrlDev: "http://localhost:1234",
        apiURL: "https://api.lastdaystudios.com/v1/",
        apiURLDev: "http://lastdaystudiosapi.test/v1/",
    }
  };
