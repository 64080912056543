import React from 'react';

class LeftMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      return (
          <ul className="navbar-nav me-auto align-items-center">
              <li className="nav-item mx-lg-1">
                  <a className="nav-link active" href="#home">Home</a>
              </li>
              <li className="nav-item mx-lg-1">
                  <a className="nav-link" href="#features">Features</a>
              </li>
              <li className="nav-item mx-lg-1">
                  <a className="nav-link" href="#cataclysm">Cataclysm</a>
              </li>
              <li className="nav-item mx-lg-1">
                  <a className="nav-link" href="#team">Team</a>
              </li>
              <li className="nav-item mx-lg-1">
                  <a className="nav-link" href="#contact">Contact</a>
              </li>
          </ul>

      );
    }
}

export default LeftMenu;
