import React from 'react';

import { config } from '../../../config'; 


class Services extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      return (

          <section className="py-5 bg-light">
            <a id="features"></a>
              <div className="container">
                  <div className="row py-4">
                      <div className="col-lg-12">
                          <div className="text-center">
                              <h1 className="mt-0"><i className="mdi mdi-firework"></i></h1>
                              <h3>Check out <span className="text-primary">Last Day To Party!</span></h3>
                              <p className="text-muted mt-2">The world is ending! Do you have what it takes to survive the apocalypse???</p>
                          </div>
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-4">
                          <div className="text-center p-3">
                              <div className="avatar-sm m-auto">
                                  <span className="avatar-title bg-primary-lighten rounded-circle">
                                    <img src={config.paths.cdn_base+'/images/disaster_icons/dark/tornado.png'} width="30px"></img>
                                  </span>
                              </div>
                              <h4 className="mt-3">Party Mode</h4>
                              <p className="text-muted mt-2 mb-0">Join forces with your firends and fight to become the champions of earth! 1-4 Players compete in a series of minigames. Try not to get elimited!
                              </p>
                              <span className="badge badge-warning-lighten rounded-pill">Coming Soon</span>
                          </div>
                      </div>

                      <div className="col-lg-4">
                          <div className="text-center p-3">
                              <div className="avatar-sm m-auto">
                                  <span className="avatar-title bg-primary-lighten rounded-circle">
                                  <img src={config.paths.cdn_base+'/images/disaster_icons/dark/volcano.png'} width="30px"></img>
                                  </span>
                              </div>
                              <h4 className="mt-3">Custom Lobbies / Private Matches</h4>
                              <p className="text-muted mt-2 mb-0">Game devs aren't aways right!? Why should we tell you how to have fun. Create your own custom lobbies and events!
                              </p>
                              <span className="badge badge-warning-lighten rounded-pill">Coming Soon</span>
                          </div>
                      </div>

                      <div className="col-lg-4">
                          <div className="text-center p-3">
                              <div className="avatar-sm m-auto">
                                  <span className="avatar-title bg-primary-lighten rounded-circle">
                                  <img src={config.paths.cdn_base+'/images/disaster_icons/dark/logging.png'} width="30px"></img>
                                  </span>
                              </div>
                              <h4 className="mt-3">Level & Minigame Editor</h4>
                              <p className="text-muted mt-2 mb-0">Think you have what it takes to be a game maker? Show us what you've got! Design levels and minigames to play with your friends or publish them and see if you can out dev the devs. (You probabbly can -Jess)
                              </p>
                              <span className="badge badge-warning-lighten rounded-pill">Coming Soon</span>

                          </div>
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-4">
                          <div className="text-center p-3">
                              <div className="avatar-sm m-auto">
                                  <span className="avatar-title bg-primary-lighten rounded-circle">
                                  <img src={config.paths.cdn_base+'/images/disaster_icons/dark/global_warming.png'} width="30px"></img>
                                  </span>
                              </div>
                              <h4 className="mt-3">Massive World</h4>
                              <p className="text-muted mt-2 mb-0">Think big! The world is 5x5km. Plenty of space for all of your highly destructive tendencies.
                              </p>
                          </div>
                      </div>

                      <div className="col-lg-4">
                          <div className="text-center p-3">
                              <div className="avatar-sm m-auto">
                                  <span className="avatar-title bg-primary-lighten rounded-circle">
                                  <img src={config.paths.cdn_base+'/images/disaster_icons/dark/bio.png'} width="30px"></img>
                                  </span>
                              </div>
                              <h4 className="mt-3">Dedicated Servers</h4>
                              <p className="text-muted mt-2 mb-0">Play on our dedicated servers or host your own! <a href="" >Download the server</a> and get started hosting your custom event!
                              </p>
                          </div>
                      </div>

                      <div className="col-lg-4">
                          <div className="text-center p-3">
                              <div className="avatar-sm m-auto">
                                  <span className="avatar-title bg-primary-lighten rounded-circle">
                                  <img src={config.paths.cdn_base+'/images/disaster_icons/dark/storm.png'} width="30px"></img>
                                  </span>
                              </div>
                              <h4 className="mt-3">Community Focuesed</h4>
                              <p className="text-muted mt-2 mb-0">Sometimes your ideas our better than ours! Join Jess live on Twitch and help build/test the game!
                              </p>
                          </div>
                      </div>
                  </div>

              </div>
          </section>

      );
    }
}

export default Services;
