import React from 'react';
import GameTitleCard from './GameTitleCard';
import ItemCard from './ItemCard';

class ProductRowWidget extends React.Component {

    constructor(props) {
        super(props);
        this.dashboard = this.props.dashboard;
        this.core = this.props.LDSCore;

        this.state = {
            productCards: [],
            showFailedToLoad: false,
        }

        this.keyItr = 0;

        //Alias
        this.core = this.props.LDSCore;
    }

    componentDidMount() {
        this.refreshStoreProducts();
    }

    componentWillUnmount() {
    }

    refreshStoreProducts(){
        this.core.getStoreProducts(this.storeProductsUpdated.bind(this));
    }

    storeProductsUpdated(data){
        console.log("GOT Product DATA IN WIDGET",data);
        if(!data || !Array.isArray(data)){
            this.setState({showFailedToLoad:true});
            return;
        }

        var productCards = [];
        for (const product of data) {
            this.keyItr++;
            productCards.push(<ItemCard 
                storeProductEntry={product} 
                dashboard={this.dashboard} 
                LDSCore={this.core} 
                key={this.keyItr+"_store_game_card"}
                type="product"></ItemCard>);
        }
        this.setState({productCards:productCards});

    }

    /* Don't think we'll need these anymore
        <TokenSale  LDSCore={this.core} price='4.99' amount='500'></TokenSale>
        <TokenSale  LDSCore={this.core} price='9.99' amount='1000'></TokenSale>
        <TokenSale  LDSCore={this.core} price='14.99' amount='2000'></TokenSale>
        <TokenSale  LDSCore={this.core} price='19.99' amount='3000'></TokenSale>*/

    render() {
            return(
                <div>
                    <h2 className="text-black mb-0">Special Offers:</h2>
                    <hr className='mt-0'></hr>
                    { this.state.showFailedToLoad ?
                    <div className='alert alert-warning text-black'><i className='mdi mdi-connection me-1'></i><strong>Error: </strong>Failed to download the product list from the server!</div>
                    : null}

                    <div className="product-grid-cards overflow-visible">
                        {this.state.productCards}
                    </div>
                </div>
            );
    }
}
export default ProductRowWidget;
