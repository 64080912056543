
import React from 'react';
import GameTitleCard from './store/GameTitleCard';

class YourGames extends React.Component {

    constructor(props) {
        super(props);
        this.core = this.props.LDSCore;
        this.dashboard = this.props.dashboard;


        this.state = {
            gameCards: [],
        };

    }

    componentDidMount(){
        this.buildGameCards();
    }

    buildGameCards(){
        var cards = [];
        for (let index = 0; index < 1; index++) {
            var coverImage = 'https://ldssite.b-cdn.net/images/games/ldtp/ldtp_game_cover_sm.png';
            var uuid = '1lkasdjf19=3kasdnfasdf=1asddnfasd';
            cards.push(<GameTitleCard coverImage={coverImage} productType='game' productId={uuid} dashboard={this.dashboard} ribbonColor="ribbon-success" ribbonText="PLAY FOR FREE" />);
        }
        var blankCover = 'https://ldssite.b-cdn.net/images/games/empty/empty_game_cover_sm.png';
        cards.push(<GameTitleCard coverImage={blankCover} productType='game' productId='empty' dashboard={this.dashboard} ribbonColor="ribbon-info" ribbonText="Visit The Store" />);
        this.setState({gameCards:cards});
    }

    render() {
      return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                   <h1 className='text-dark mb-0'>Your Games:</h1>
                </div> 
            </div>
            <hr className='mt-0'></hr>
            <div className="grid-cards">
                {this.state.gameCards}
            </div>
        </div>
      );
    }
}

export default YourGames;

