import React from 'react';
import { config } from "../../../../../config";
import PriceCard from './PriceCard';

class ItemCard extends React.Component {

    constructor(props) {
        super(props);
        this.core = this.props.LDSCore;
        this.dashboard = this.props.dashboard;
        this.productType = 'item';

        if(this.props.type){
            this.productType = this.props.type;
        }
 
         this.fullStoreEntry = this.props.storeProductEntry;
         //Defaults
         this.ribbonColor = 'ribbon-primary';
         this.currencyType = 'usd';
 
 
         //Check for a full game entry to intialize from
         if(this.fullStoreEntry){
             this.coverImage = this.fullStoreEntry.icon_url_lg;
 
             if(this.fullStoreEntry.price==0.0){
                 this.ribbonText = "Play For Free!";
                 this.ribbonColor = 'ribbon-success';
             }
             
             this.productId = this.fullStoreEntry.uuid;
             this.productName = this.fullStoreEntry.product_name;
             this.productPrice = this.fullStoreEntry.price;
             this.discount = this.fullStoreEntry.discount;
             if(this.fullStoreEntry.currency_type){
                this.currencyType = this.fullStoreEntry.currency_type;
             }
             
         }
 
       
        this.state = {
        };
    }
    
    cardClicked(){
        this.dashboard.openProductPage(this.productType,this.productId);
    }

    render() {
        return (
            
            <div className="card ribbon-box game-item-card clickable" onClick={this.cardClicked.bind(this)}>
                <div className="card-body p-0 m-0" >
                    <img className='game-item-card-bg  card-shimmer-effect' src={this.coverImage}></img>
                    { this.ribbonText ?
                    <div className={this.ribbonColor+"ribbon ribbon-success float-end mt-2 game-item-ribbon text-dark"}><i className="mdi mdi-google-controller me-1"></i>{this.ribbonText}</div>
                    : null}
                    </div>

                    <PriceCard price={this.productPrice} discount={this.discount} currencyType={this.currencyType} title={this.productName}></PriceCard>

                   
            </div>
      );
    }
}
export default ItemCard;


