import React from 'react';

class ServiceAuth extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
      return (
        <div>Finishing Auth Request</div>
      );
    }
}

export default ServiceAuth;
