
import React from 'react';
import PriceCard from './PriceCard';

class PurchaseCard extends React.Component {

    constructor(props) {
        super(props);

        this.parent = this.props.parent;

        this.currencyType = 'usd';
        this.isPlayable = false;


        this.purchaseImage = this.props.purchaseImage;
        this.price = this.props.price;
        this.discount = this.props.discount;
        this.itemType = this.props.type;
        this.itemUUID = this.props.uuid;
        this.isOwned = this.props.isOwned;
        this.isPlayable = this.props.playable;
        this.title = this.props.title;

        
        if(this.props.currencyType){
            this.currencyType = this.props.currencyType;
        }

        this.priceAfterDiscount = Math.round(this.price*(1-this.discount)* 100)/100;

        if(this.props.currencyType){
                this.currencyType = this.props.currencyType;
        }

         this.currencySymbol = '$';
         switch (this.currencyType) {
             case 'usd':
                 this.currencySymbol = '$';
                 break;
             case 'tokens':
                 this.currencySymbol = <img className="apocalypse-token-icon d-inline"></img>;
                 break;
             case 'free_tokens':
                 this.currencySymbol = <img className="free-token-icon d-inline"></img>;
                 break;
             default:
                 break;
         }
         

        this.state = {
        };
    }


    addToLibraray(){

    }

    buyNow(){

    }

    playNow(){
        this.parent.playNow();
    }

    render() {
      return (
            <div className="">
                <div className='card bg-store-cover-card border border-secondary'>
                    <div className='card-body'>
                        <img className='game-overview-cover-image border border-white' src={this.purchaseImage}></img>

                        <br></br>
                        <br></br>

                        <PriceCard price={this.price} discount={this.discount} currencyType={this.currencyType} title={this.title}></PriceCard>
                        
                        <br></br>

                        
                        {this.price>0 ?
                        <div className='btn btn-info btn-lg border text-white border-white'
                        onClick={this.buyNow.bind(this)}>Buy Now</div>
                        : null}

                        {this.isOwned ? 
                            <div>
                            { this.isPlayable ? 
                                <div className='btn btn-success btn-lg border text-white border-white'
                                onClick={this.playNow.bind(this)}>Play Now</div>
                            : null}
                             </div>
                        :
                            <div className='btn btn-success btn-lg border text-white border-whiteborder text-white border-white'
                            onClick={this.addToLibraray.bind(this)}>Add to Library (FREE)</div>
                        }

                    </div>
                </div>

            </div>
      );
    }
}

export default PurchaseCard;

