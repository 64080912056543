//import logo from './logo.svg';
//import './App.css';
import React from 'react';

import LoadingScreen from './LoadingScreen';



class TermsAndConditions extends React.Component {


    constructor(props) {
        super(props);

        this.core = this.props.LDSCore;

        this.formState = {
            terms_shown: false,
        }

        this.handleTOSAccept = this.handleTOSAccept.bind(this);
        this.handleTosChange = this.handleTos.bind(this);

    }

    componentDidMount() {
        this.openTermsIframe();
        document.getElementById('accept_button').disabled = true;
    }

    handleTos(event) {
        console.log(event.target.checked);
        if(event.target.checked){
            console.log("checked");
            if(this.formState.terms_shown){
                console.log("shown");
                document.getElementById('accept_button').disabled = false;
            }
        }else{
            console.log("not checked");
            document.getElementById('accept_button').disabled = true;
        }
    }

    openTermsIframe(){
        var tosPath = this.core.getConfigValue('tos_path');

        console.log("Terms Path",tosPath);
        if(tosPath){
            document.getElementById('terms').src = tosPath;
            this.formState.terms_shown = true;
        }
    }

    handleTOSAccept(event){
        console.log("Accept Clicked");
        document.getElementById('tos_submit_loading').hidden = false;
        document.getElementById('tos_form').hidden = true;

        var tosVersion = this.core.getConfigValue('tos_version');
        //submit change to db
        // Add a new document in collection "cities"
        this.core.setAndAcceptTOS(tosVersion,this.tosAcceptCallback.bind(this));
    }

    tosAcceptCallback(result,error){
        //call the parent callback
        console.log("TOS callback",result,error);
        this.props.acceptTosCallback(result,error);
    }


    render() {
        return (
            <div>
                <div id="tos_form">
                    <br></br><br></br>
                    <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
                    </div>

                    <div className="card alert-danger bg-danger-lighten text-black shadow">
                        <div className="card-body">
                            <strong>Hi, We've updated our terms and condtions!</strong>
                        </div>
                    </div>


                    <iframe id="terms" className="terms-iframe"></iframe>

                    <div className="alert-warning bg-warning-lighten p-2 text-black">

                        <div className="form-group">
                            <div className="custom-control custom-checkbox small">
                                <input type="checkbox" className="custom-control-input" id="terms_check" onChange={this.handleTosChange} ></input>
                                <label className="custom-control-label" htmlFor="terms_check"><strong>I am 13 years of age or older and I agree to the terms & conditions and privacy policy.</strong></label>
                            </div>
                        </div>
                    </div>

                    <br></br>

                    <button id="accept_button" className="btn btn-success" onClick={this.handleTOSAccept} >
                    Accept Terms & Conditions
                    </button>

                    <br></br><br></br>

                </div>
                <div id="tos_submit_loading" hidden>
                    <LoadingScreen loadingMessage="Saving Changes!"/>
                </div>

            </div>


        );
    }
}
export default TermsAndConditions;
