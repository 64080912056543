
import React from 'react';

class GameCarouselPreview extends React.Component {

    constructor(props) {
        super(props);

        this.gameData = this.props.gameData;
        this.parentElement = this.props.parentElement;

        console.log("PARENT IS:",this.parentElement);

        this.state = {
            screenshotsArr: [],
            thumbnailArr: [],
        };
    }

    componentDidMount(){
        var thumbnails = this.createThumbnails(this.gameData.game_screenshot_arr);

        this.setState({thumbnailArr:thumbnails});
    }




    createThumbnails(screenshotArrStr){
        var cards = [];
        var urls = screenshotArrStr.split(',');
        console.log("URLS ARE",urls);

        if(this.gameData.trailer_preview_thumbnail){
            cards.push(
                <div className="d-inline ms-1" key={"c_screenshot_thumb_v1"} onClick={this.openTrailerVideo.bind(this)}>
                    <img className="carousel-thumbnail " src={this.gameData.trailer_preview_thumbnail}></img>
                </div>
            );
        }

        if(this.gameData.gameplay_preview_thumbnail){
            cards.push(
                <div className="d-inline ms-1" key={"c_screenshot_thumb_v2"} onClick={this.openGameplayVideo.bind(this)}>
                    <img className="carousel-thumbnail " src={this.gameData.gameplay_preview_thumbnail}></img>
                </div>
            );
        }        
        

        for (const [idx,addy] of urls.entries()) {
            
                cards.push(
                    <div className="d-inline ms-1" key={"c_screenshot_thumb_"+idx} id={"c_screenshot_thumb_"+idx} onClick={this.imageSelected.bind(this,idx)}>
                        <img className="carousel-thumbnail " src={addy}></img>
                    </div>
                );
    

        }
        return cards;

    }

    openTrailerVideo(){
        this.parentElement.openTrailerVideo();

    }

    openGameplayVideo(){
        this.parentElement.openGameplayVideo();

    }

    imageSelected(idx,el){
        this.parentElement.openGalery();
        $('.carousel-item').removeClass('active');
        $('#screenshot_card_'+idx).addClass('active');

        console.log("selected",el,idx);
    }


    render() {



      return (
        <div>

           

            <div className='mt-1 carousel-thumbnail-outer'>
                <div className='carousel-thumbnail-row' style={{width:this.state.thumbnailArr.length*160+"px"}}>
                    {this.state.thumbnailArr}
                </div>
            </div>

        </div>
      );
    }
}

export default GameCarouselPreview;

