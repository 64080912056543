import React from 'react';
import LinkedAccountBase from './LinkedAccountBase';

class LinkLDSModAccount extends LinkedAccountBase {

    constructor(props) {
        super(props);

        this.serviceName = 'twitch_mod';

    }

    componentDidMount() {
        this.requestCredential();
    }

    componentWillUnmount() {

    }

    
    componentWillReceiveProps(nextProps) {//Lifecycle method to get the updated props
        this.setState({ 
            linkInProgress: nextProps.linkInProgress,
            error:nextProps.error,
            errorMessage:nextProps.errorMessage,
            linkSuccess:nextProps.linkSuccess,
            credential: nextProps.credential,
         });
    }

    render() {
            return(
                <div className="card ribbon-box">
                    <div className={this.state.linkSuccess ? "card-body bg-success-lighten border border-success" : "card-body"}>
                        {this.state.error ? <div className="alert alert-danger bg-danger text-white" ><b> Error: {this.state.errorMessage}</b></div>: null}
                        {this.state.linkInProgress ? 
                        <div className="alert alert-danger bg-warning text-black" >
                            <div className="spinner-border spinner-border-sm text-black me-2" role="status"></div>
                            <b>Waiting for credentials: Please do not close this page!</b>
                        </div>: null}


                        {this.state.linkSuccess ? 
                        <h4 className="text-black float-start mt-0"><b className="text-black"><i className="mdi mdi-check-bold text-black"></i> Twitch Account Linked!</b></h4>
                        : 
                        <h4 className="text-primary float-start mt-0">Twitch (Advanced)</h4>
                        }

                        <div className="ribbon-content">

                            <b>Link the LDS Mod account.</b>
                                <div style={{maxWidth:'800px'}}>
                                    {!this.state.linkSuccess ? <div className="btn btn-info" onClick={this.get3rdPartyLink.bind(this)}>Link Your Twitch Account</div>: null}
                                    {this.state.credential!=null ? <div className="btn btn-danger bg-danger text-black" onClick={this.unlinkService.bind(this)}><b><i className="mdi mdi-trash-can-outline pe-2"></i>Unlink Your Twitch Account</b></div>: null}
                                </div>
                        </div> 
                    </div>
                </div>
               
            );
    }
}
export default LinkLDSModAccount;
