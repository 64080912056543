
import Verification from "../../helpers/Verification.js";

export default class MultiplayerServices{

    //NOTE: This api requires elevated permissions to access

    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;

        this.webRequest = webRequest;

        //Create Events
        this.events.createEvent('thirdPartyCredsUpdated');


        this.instanceID = null;
        this.instanceData = null;
        this.cataclysmAccountID = null;

        this.credentials = new Map();

    }

    get3rdPartyCredByServiceName(serviceName){
        if(this.credentials.has(serviceName)){
            return this.credentials.get(serviceName);
        }
        return null;
    }

    bindAndInitializeMPSInstance(instanceID,ip,port,name,callback){
        this.instanceID=instanceID;

        var params = {
            instance_id: instanceID,
            server_name:name,
            server_ip:ip,
            server_port:port,
        };



        this.webRequest.basic('multiplayer_services/bind_and_initialize',this.bindAndInitializeMPSInstanceCallback.bind(this,callback),params,null);

    }

    bindAndInitializeMPSInstanceCallback(callback,res){
        if(res!=undefined && res.status=='success'){
            if(res.data!=undefined){

                this.instanceData = res.data;

                if(res.data.owner_id){
                    this.cataclysmAccountID = res.data.owner_id;
                    this.getUsers3rdPartyCredentials();
                }
            }
            callback(true);
            return;
        }
        console.log(res);
        callback(false);
    }

    getUsers3rdPartyCredentials(){
        this.webRequest.basic('multiplayer_services/get_third_party_credentials',this.getUsers3rdPartyCredentialsCallback.bind(this),null,null);
    }

    getUsers3rdPartyCredentialsCallback(res){
        if(res && res.status=='success' && res.data){
            for (const cred of res.data) {
                this.credentials.set(cred.service_name,cred);
            }
            this.events.thirdPartyCredsUpdated.trigger();
        }
    }

}
