import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ProfileSummary from '../components/ProfileSummary';
import YourGames from '../components/YourGames';
import YourItems from '../components/YourItems';
import Footer from '../../both/Footer';



class DashboardHome extends React.Component {

    constructor(props) {
        super(props);
        this.dashboard = this.props.dashboard;
        //Alias
        this.core = this.props.LDSCore;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
            return(
                <div>
                    <div className='content'>
                        <div className='container-fluid'>

                        <Breadcrumb title="Your Library" pageTree={{Dashboard:'#',Overview:'#'}}> </Breadcrumb>

                        <div className="row">
                        <div className='col-lg-4  order-lg-2'>
                                <ProfileSummary LDSCore={this.core}></ProfileSummary>

                            </div>
                            <div className="col-lg-8">
                                <YourGames LDSCore={this.core} dashboard={this.dashboard}></YourGames>
                                <YourItems LDSCore={this.core} dashboard={this.dashboard}></YourItems>
                            </div>

                        </div>

                        

                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            );
    }
}
export default DashboardHome;
