import * as React from "react";
import { render } from "react-dom";

import App from './pages/App';

//App config values, cloud accout, etc
import { config } from "./../config";
//Import and intialize LDS_Core
import { LDSCore } from './LDSCore/LDSCore';
var ldsCore = new LDSCore(config);


render(
    <App LDSCore={ldsCore}/>,
    document.getElementById("root")
);
