
import Verification from "./../../helpers/Verification.js";

export default class GlobalConfig{

    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;

        this.webRequest = webRequest;

        this.data = new Map();

        //Create events
        this.events.createEvent('globalConfigUpdated');

    }

    connect(){
        this.downloadGlobalConfig();
    }

    downloadGlobalConfig(){
        var parms = {'category':'global'};
        this.webRequest.basic('get_config_category',this.processGlobalConfig.bind(this),parms,null)
    }

    processGlobalConfig(res){
        if(res==undefined){
            return;
        }
        if(res.status=='success' && res.data!=undefined && Array.isArray(res.data)){
            for (const item of res.data) {

                switch (item.cast_type) {
                    case 'integer':
                        this.data.set(item.attribute_name,parseInt(item.value));
                        break;
                    case 'float':
                        this.data.set(item.attribute_name,parseFloat(item.value));
                        break;
                    default:
                        this.data.set(item.attribute_name,item.value);
                }

                
            }
        }
        console.log("Config is",this.data);
        
    }

    getConfigValue(attributeName){
        console.log("Getting global config value for",attributeName,this.data);
        var value = this.data.get(attributeName);
        console.log("FOUND ",value);
        return value;
    }


}
