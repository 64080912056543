import React from 'react';
import NavBar from './dashboard_view/components/NavBar';
import NavSecondary from './dashboard_view/components/NavSecondary';
import DashboardPageSwitcher from './dashboard_view/DashboardPageSwitcher';
import Footer from './both/Footer'

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.core = this.props.LDSCore;
        this.nextPage = this.props.nextPage;
        this.pageSwitcher = null;

      this.state = {
        page: 'home',
      }

    }

    componentDidMount() {
        
    }


  setPage(page){
    this.pageSwitcher.setPage(page);
    this.setState({page:page});
  }


    //TODO verify user is logged in and subscribe to logout event
    // Make sure the user didnt skip the propper login screen

    render() {
      return (
          <div>
            <div className="wrapper">
               <NavBar LDSCore={ this.props.LDSCore } app={this.props.app} />
              <NavSecondary LDSCore={ this.props.LDSCore } app={this.props.app} parent={this} />
              <DashboardPageSwitcher LDSCore={ this.props.LDSCore } app={this.props.app} parent={this} nextPage={this.nextPage}/>
            </div>
          </div>
      );
    }
}

export default Dashboard;
