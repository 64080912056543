
import React from 'react';

class GameCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.gameData = this.props.gameData;
        this.parentElement = this.props.parentElement;

        console.log("PARENT IS:",this.parentElement);

        this.state = {
            screenshotsArr: [],
            thumbnailArr: [],
        };
    }

    componentDidMount(){
        var screenshots = this.createScreenshotCards(this.gameData.game_screenshot_arr);

        this.setState({screenshotsArr:screenshots});
    }


    createScreenshotCards(screenshotArrStr){
        var cards = [];
        var urls = screenshotArrStr.split(',');
        console.log("URLS ARE",urls);

        for (const [idx,addy] of urls.entries()) {
            
                cards.push(
                    <div className="carousel-item" key={"screenshot_card_"+idx} id={"screenshot_card_"+idx}>
                        <img className="d-block img-fluid" src={addy}></img>
                    </div>
                );
    

        }
        return cards;

    }



    openTrailerVideo(){
        this.parentElement.openTrailerVideo();

    }

    openGameplayVideo(){
        this.parentElement.openGameplayVideo();

    }




    render() {



      return (
        <div>

            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active" key={"screenshot_card_v1"} onClick={this.openTrailerVideo.bind(this)}>
                        <img className="d-block img-fluid" src={this.gameData.trailer_preview_thumbnail}></img>
                    </div>
                    <div className="carousel-item " key={"screenshot_card_v2"} onClick={this.openGameplayVideo.bind(this)}>
                        <img className="d-block img-fluid" src={this.gameData.gameplay_preview_thumbnail}></img>
                    </div>
                    
                    
                    {this.state.screenshotsArr}
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </a>
            </div>



        </div>
      );
    }
}

export default GameCarousel;

