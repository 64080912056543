import React from 'react';
import LinkedAccountBase from './LinkedAccountBase';

class LinkStreamLabs extends LinkedAccountBase {

    constructor(props) {
        super(props);



       
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {

        if(this.props.disabled != undefined){
            return( <div className="alert alert-danger bg-secondary-lighten text-black"> Stream Labs Integration (Disabled)(Coming Soon)</div>);
        }

        return(
            <div className="card ribbon-box">
                <div className="card-body">
                    <div className="ribbon ribbon-info float-end"><i className="mdi mdi-lightning-bolt me-1"></i>Used with Cataclysm</div>

                    <h5 className="text-primary float-start mt-0">Stream Labs</h5>
                        
                        <div className="ribbon-content">
                        <b>Link your Stream Labs account for interactive donation events in your Cataclysm.</b>
                        <p className="mt-1">
                            Linked Accounts:
                            <ul>
                                <li>Can use donations to trigger events</li>
                                <li>Can use the Sasquatch Ultimate</li>
                            </ul>
                        </p>
                        <div className="btn btn-info">Link Your Stream Labs Account</div>
                    </div>
                        
                </div> 
            </div>
        );
    }
}
export default LinkStreamLabs;
