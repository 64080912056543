import uuidv4 from "./UUIDV4.js";

export default class BasicEvent{

  constructor(eventName){
          this.eventName = eventName;
          this.listenerCallbacks = new Map();
        }

  bindEvent(event){
      var uuid = uuidv4();
      this.listenerCallbacks.set(uuid,event);
      return uuid;
  }

  //Bind an event handle that was created before the event was initialized
  preemptiveBindEvent(uuid,event){
    this.listenerCallbacks.set(uuid,event);
  }

  unbindEvent(eventID){
    if(this.listenerCallbacks.has(eventID)){
      this.listenerCallbacks.delete(eventID);
      return true;
    }else{
        return false;
    }
  }

  unbindAll(){
    for (const [key, value] of Object.entries(this.listenerCallbacks)) {
      this.unbindEvent(key);
    }
  }

  trigger(data,callee=''){
    //console.log("Event "+this.eventName+" triggered");
    for (let [key, event_callback] of this.listenerCallbacks) {
          try {
            event_callback(data);
          } catch (error) {
            console.log("event callback exception",key,event_callback,error);
          }
         }
  }

}
