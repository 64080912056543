import React from 'react';

class LinkedAccountBase extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;

        //Set in children
        this.serviceName = null;

        this.state = {
            linkInProgress: false,
            error: false,
            errorMessage: '',
            linkSuccess: false,
            credential: null, //the actual entry and uuid
            checkedForCreds: false,
        }

        this.core.events.credentialsUpdated.bindEvent(this.allCredsUpdatedEvent.bind(this));

    }

    componentDidMount(){
        
    }

    setChildRef(ref){
        this.child = ref;
        this.child.setParentRef(this);
    }

    componentDidUpdate(){
    }


    allCredsUpdatedEvent(){
        console.log("ALL CREDS UPDATED!");
        this.requestCredential();
    }

    requestCredential(){
        var cred = this.core.getCredential(this.serviceName);
        if(cred!=null){
            this.setState({linkSuccess:true,credential:cred});
            console.log("HAS CRED")
        }else{
            console.log("NO CRED");
        }
    }

    get3rdPartyLink(){
        this.core.get3rdPartyLink(this.serviceName,this.open3rdPartyAuthInNewTab.bind(this));
        this.setState({linkInProgress:true,linkSuccess:false,error:false,errorMessage:''});
    }

    open3rdPartyAuthInNewTab(data){
        console.log("GOT RES IN COMPONENT",data);
        if(data!=undefined && data.url!=undefined){
            console.log("Opening Window");
            this.openAuthWinow = window.open(data.url, '_blank');
            this.checkCompleteInterval = setInterval(this.checkForClose.bind(this),1000);
            this.setState({linkInProgress:true});
        }else{
            console.log("ERROR");
        }
    }

    checkForClose(){
        console.log("Checking close",this.openAuthWinow);
        if(this.openAuthWinow == null || this.openAuthWinow.closed == true){
            this.authComplete();
        }

    }

    authComplete(){
        console.log("AUTH COMPLETED CALLED");
        this.setState({linkInProgress:false});
        clearInterval(this.checkCompleteInterval);
        //Have core pull creds and report back
        this.core.saveThirdPartyAuthAttempt(this.authCompleteCallback.bind(this));
    }

    authCompleteCallback(res){
        console.log("GOT AUTH FINAL RESUTLS",res);
        if(res==undefined){
            this.setState({error:true,errorMessage:'no response'});
            return;
        }

        if(res.status!=undefined && res.status=='success'){
            console.log("LINK SUCCESS!!!!!!!!");
            this.setState({linkInProgress:false,linkSuccess:true});
            return;
        }

        if(res.status!=undefined && res.status=="error" && res.message!=undefined){
            this.setState({error:true,errorMessage:res.message});
            return;
        }
        this.setState({error:true,errorMessage:'Unknown'});
    }

    unlinkService(){
        if(this.state.credential!=null && this.state.credential.uuid != undefined){
            this.core.deleteCredential(this.state.credential.uuid,this.unlinkServiceCallback.bind(this));
        }
    }

    unlinkServiceCallback(res){
        if(res){
            this.setState({linkSuccess:false,credential:null});
        }
    }

}
export default LinkedAccountBase;
