import React from 'react';

import Breadcrumb from '../components/Breadcrumb';
import ProfileSummary from '../components/ProfileSummary';
import TokenSale from '../components/TokenSale';
import GameStoreRowWidget from '../components/store/GameStoreRowWidget';
import ProductRowWidget from '../components/store/ProductRowWidget';
import ItemRowWidget from '../components/store/ItemRowWidget';
import FooterStore from '../../both/FooterStore'
import TokenWidget from '../components/store/TokenWidget';


class Store extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
        this.dashboard = this.props.dashboard;


    }

    componentDidMount(){
        document.body.classList.add("store-bg");

    }

    componentWillUnmount(){
        document.body.classList.remove("store-bg");

    }


    render() {
            return(
                <div>
                    <div className='container content'>
                        <div className='container-fluid'>
                            <div className="card p-0 bg-light rounded-0 mb-0">
                                <img className="store-header p-0" src="https://ldssite.b-cdn.net/images/lds_store_header_2.jpg"></img>
                                
                                <div className='card-body p-0 store-body '>
                                <div className='token_widget '>
                                    <TokenWidget LDSCore={this.core} dashboard={this.dashboard}></TokenWidget>
                                </div>

                                <div className='p-2 pt-1'>

                                <div className='row '>
                                    <div className='col' style={{maxWidth:'400px'}}>
                                        <GameStoreRowWidget LDSCore={this.core} dashboard={this.dashboard}/>
                                    </div>
                                    <div className='col'>
                                        <ProductRowWidget LDSCore={this.core} dashboard={this.dashboard}/>
                                    </div>
                                </div>
                                
                                <ItemRowWidget LDSCore={this.core} dashboard={this.dashboard}/>

                                </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterStore></FooterStore>

                </div>
            );
    }
}
export default Store;
