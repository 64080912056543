import React from 'react';

class ExternalContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageContent: null,
        };

        this.url = this.props.url;
        this.core = this.props.core;
    }

    componentDidMount(){
        if(this.url){
            this.core.backend.webRequest.custom(this.url,this.pageLoaded.bind(this));
        }
    }

    pageLoaded(data){
        if(data.status != 200){
            return;
        }
        this.setState({pageContent:data.data});
    }

    render() {

        if(!this.state.pageContent){
            return (
                <div className='alert alert-secondary'>
                    <div class="spinner-grow spinner-grow-sm" role="status"></div>
                    <div class="spinner-grow spinner-grow-sm" role="status"></div>
                    <div class="spinner-grow spinner-grow-sm me-2" role="status"></div> 
                    Loading...
                </div>
              );
        }

        return(
            <div className='text-dark'>
                <div id="game_overview_content_111" dangerouslySetInnerHTML={{__html: this.state.pageContent}}>
                </div>
            </div>
        );

    }
}
export default ExternalContent;
