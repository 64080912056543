import React from 'react';
import { config } from '../../../../config'; 


class FooterContents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      return (
          <footer className="bg-dark ">
              <div className="container">
              <div className="row m-0">
                <div className="col m-0">
                        <img src={config.paths.cdn_base+"/images/lastdaystudios_light_md.png"} alt="" className="logo-dark mt-3" height="30" />
                    </div>
                </div>
                    <div className="row m-0 mt-2">
                   
                      <div className="col m-0 ">
                        <div className="text-muted  ">©2023 Last Day Studios LLC</div>
                      </div>
                      <div className="col m-0">
                              <div className="text-muted  ">Presskit <div className="badge badge-warning-lighten rounded-pill">coming soon</div></div>
                      </div>
                      <div className="col m-0">
                              <a className="text-muted" href="#contact">Contact</a>
                      </div>
                      <div className="col m-0">
                              <div className="text-muted  ">Terms & Conditions</div>
                      </div>
                  </div>
                  <br></br>
              </div>
          </footer>
      );
    }
}

export default FooterContents;
