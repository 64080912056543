import React from 'react';

import { config } from '../../../config'; 
import Footer from '../both/Footer';


class Cataclysm extends React.Component {

    constructor(props) {
        super(props);

        this.app = this.props.app;

        this.state = {
        };
    }

    signup(){
        this.app.setNewMainPage('dashboard',true,'cataclysm');
    }

    render() {
      return (
          <div className="sectionbackground">
            <a id="cataclysm"></a>
          <div className="sectioncolored">
          <section id="store" className="py-5 bg-light-lighten border-top border-bottom border-light ">

              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="text-center text-white  mb-4">

                              <h1 className="mt-0">
                                <img src={config.paths.cdn_base+'/images/disaster_icons/light/tsunami.png'} width="50px"></img>
                                </h1>

                              <h3><span className="text-success">Cataclysm - <em>A <del>cult</del> game for streamers and chat!</em></span></h3>
                              <p className="text-white mt-2">Are you sick of your lonely streamer cave? Do you want to escape into a larger virtual world? Do you need an apocalypse to worry about? 
                              <br></br>Does chat need more ways to annoy you? </p>

                              <h4 className="text-warning">THIS GAME MODE IS FOR YOU!</h4>

                               

                          </div>

                          <div className="row">
                                    <div className="col-lg-8">
                                        <video className="w-100" poster={config.paths.cdn_base+'/images/trailer_poster.jpg'} autoPlay muted loop controls>
                                            <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.webm'} type="video/webm"/>
                                            <source src={config.paths.cdn_base+'/images/ldtp_web_trailer.mp4'} type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div className="col-lg-4 ">
                                            <h2 className="fw-normal text-success">Features</h2>

                                            <div className="mt-2">
                                                <p className="text-white"><i className="mdi mdi-circle-medium text-success"></i> Twitch Chat Integration</p>
                                                <p className="text-white"><i className="mdi mdi-circle-medium text-success"></i> Massively Multiplayer World (100 players)</p>
                                                <p className="text-white"><i className="mdi mdi-circle-medium text-success"></i> Story Mode</p>
                                                <p className="text-white"><i className="mdi mdi-circle-medium text-success"></i> Solve the mystery with chat!</p>
                                            </div>

                                            <h2 className="fw-normal text-success">Requirements</h2>
                                            <div className="mt-2">
                                                <p className="text-white"><i className="mdi mdi-circle-medium text-success"></i> 5000 Followers On Twitch*</p>
                                                <p className="text-white"><i className="mdi mdi-circle-medium text-success"></i> Twitch Affiliate or Partner*</p>
                                            </div>
                                            <a className="text-muted" href="#" data-bs-toggle="tooltip" data-bs-title=" Cataclysm was designed for medium-large streamers... We still need to change the game rules a bit for smaller audiances.">* We hope to remove this requirement soon.</a>
                                            
                                            <p className="text-info fw-bold mt-3">Join now to get your own slice of the multiverse. You and 100 players can join your own private island concert venue.</p>

                                            <a href="#" onClick={this.signup.bind(this)} className="btn btn-info rounded-pill mt-2">Sign Up <i className="mdi mdi-arrow-right ms-1"></i></a>

                                            
                                    </div>

                                    
                                    
                                </div>
                                

                      </div>
                  </div>

    

              </div>

          </section>
          </div>
          </div>

      );
    }
}

export default Cataclysm;
