import React from 'react';
import Footer from '../../../both/Footer';



class CataclysmSignup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cataclysmActive: false,
            isStreamerEnrolled: false,
            signUpError: false,
        }

        //Alias
        this.core = this.props.LDSCore;

        this.core.events.cataclysmUpdated.bindEvent(this.cataclysmUpdatedEvent.bind(this));
    }

    componentDidMount() {
        var active = this.core.isCataclysmActive();
        if(active){
            this.setState({cataclysmActive:true});
        }
    }

    componentWillUnmount() {

    }

    signUpClicked(){
        this.core.cataclysmSignup();
    }

    shutdownClicked(){
        this.core.cataclysmShutdown();
    }

    cataclysmUpdatedEvent(data){
        var newState = {};
        
        if(data!=null){
            newState.cataclysmActive = true;
        }else{
            newState.cataclysmActive = false;
        }

        if(data.cataclysm_account != undefined && data.cataclysm_account.active==true){
            newState.isStreamerEnrolled = true;
        }else{
            newState.isStreamerEnrolled = false;
        }

        this.setState(newState);
    }


    render() {

        if(!this.state.cataclysmActive){
            return(<div className="alert alert-secondary bg-secondary-lighten text-black"><div className="spinner-border text-black spinner-border-sm me-2" role="status"></div>Connecting to Cataclysm</div>);
        }

        if(this.state.isStreamerEnrolled){
            return(
                <div>
                    <div className="alert alert-warning bg-info-warning text-black">
                        <div className="text-center">
                            <strong>Done with The Cataclysm? </strong>
                            <br></br>Click here to shutdown your server to save resources for other streamers!
                            <br></br><strong>WARNING THIS WILL REMOVE YOUR DATA AND UNBAN ALL BAN WINNERS</strong>
                            <br></br>
                            <div className="btn btn-danger" onClick={this.shutdownClicked.bind(this)}>Shutdown Cataclysm</div>
                        </div>
                    </div>
                </div>
            );
        }

        
        return(
            <div>
                <div className="alert alert-info bg-info-lighten text-black">
                    <div className="text-center">
                        <h3>You're Not Signed Up</h3>
                        <br></br>
                        {this.state.signUpError ? 
                            <div className="alert alert-danger bg-danger text-dark p-0">Test</div>
                        : null}

                        <div className="btn btn-success text-black" onClick={this.signUpClicked.bind(this)}><strong>CLICK HERE TO SIGN UP</strong></div>
                    </div>
                </div>

                <div className="alert alert-success bg-info-success text-black">
                    <div className="text-center">
                        <h3>You're Signed Up!</h3>
                        <strong>1) Link Your Accounts</strong>
                        <br></br><strong>2) Change Your Settings</strong>
                        <br></br><strong>3) Launch LDTP go to The Cataclysm and You'll see your server</strong>
                    </div>
                </div>


            </div>
        );
    }
}
export default CataclysmSignup;

