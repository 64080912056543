import React from 'react';
import $ from 'jQuery';
import { config } from '../../../../config';


import Breadcrumb from '../components/Breadcrumb';
import GameCarousel from '../components/GameCarousel';
import GameCarouselPreview from '../components/GameCarouselPreview';
import PurchaseCard from '../components/store/PurchaseCard';
import ExternalContent from '../components/ExternalContent';


class ProductOverview extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
        this.productId = this.props.productId;
        this.parent = this.props.parent;

        this.state = {
            productData: null,
            owner: false,
        }

    }

    componentDidMount() {
        //Get game
        var data = this.core.getStoreProduct(this.productId);
        console.log("GOT Product DATA",this.productId,data);
        if(!data){
            return;
        }

        var isOwner = this.core.isProductInUsersLibrary('game',data.uuid);

        this.setState({productData:data,owner:isOwner});
        console.log("GOT GAME",data);

        //TODO debug delete these
        data.web_background_url=null;

        if(data.web_background_url){
            document.body.style.backgroundImage = 'url('+data.web_background_url+')';
            document.body.classList.add("product-overview-bg");
        }


    }

   
    componentWillUnmount() {
        document.body.classList.remove("product-overview-bg");
        document.body.style.backgroundImage = null;
    }

    playNow(){
        this.parent.playNow(this.state.productData.store_title);
    }

    render() {

        if(!this.state.productData){
            return(
                <div className='content'>
                        <div className='container-fluid'>

                           Loading Product Page

    
                        </div>
                    </div>
            );
        }

            return(
                <div className='content'>



                        <div className='container container-fluid '>
                                
                            <Breadcrumb title={this.state.productData.store_title} pageTree={{Store:'#','Game Overview':'#'}} style="dark"> </Breadcrumb>
                            

                            <div className='row'>
                               
                                <div  className='col-md-8'>

                                    <div className=" d-md-none d-lg-none d-xl-none">
                                            <h1 className="text-white">{this.state.productData.store_title}</h1>
                                            <div className='text-white'>
                                                {this.state.productData.store_description}
                                            </div>
                                        </div>
                                    

                                   <div className=' '>
                                    <div className='card-body'>
                                        <div className="d-none d-sm-none d-xs-none d-md-block">
                                            <h1 className="text-dark">{this.state.productData.store_title}</h1>
                                            <div className='text-secondary'>
                                                {this.state.productData.store_description}
                                            </div>
                                        </div>

                                        <br></br>


                                        {this.state.owner ?
                                            <div className='alert alert-success p-1 text-dark'>
                                                <div className='btn btn-success me-1' onClick={this.playNow.bind(this)}>Play Now</div> 
                                                You've purchased <strong>{this.state.productData.store_title}</strong> before! 
                                                </div>
                                        : null }

                                            <div className='text-center'>
                                                <img className="product-page-image border border-dark" src={this.state.productData.icon_url_sm} ></img>
                                            </div>

                                        <br></br>
                                        <ExternalContent core={this.core} url={this.state.productData.store_page_content_url}></ExternalContent>

                                    </div>
                                 
                                   </div>
                
                                </div>

                                <div className='col-md-4'>
                                            <PurchaseCard 
                                            purchaseImage={this.state.productData.icon_url_sm} 
                                            price={this.state.productData.price}  
                                            discount={this.state.productData.discount}  
                                            type='product' 
                                            uuid={this.state.productData.uuid} 
                                            isOwned={this.state.owner}
                                            parent={this}
                                            playable={false}
                                            currencyType={this.state.productData.currency_type}
                                            ></PurchaseCard>
                                            

                                </div>


                            </div>

                        </div>
                    </div>
            );
    }
}
export default ProductOverview;
