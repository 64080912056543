import React from 'react';

import { config } from "../../../../../config";
import Footer from '../../../both/Footer';
import Breadcrumb from '../../components/Breadcrumb';


class CataclysmSettings extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
        this.parent = this.props.parent;

        this.state = {
            target_channel: '',
            allow_banned_players_to_enter: false,
            allow_banned_players_to_chat: false,
            allow_players_to_join: true,
            max_players: 100,
            tiltify_campaign_id: null,
            unsavedChanges: false,
            saveSuccessful: false,
        }

        this.core.events.cataclysmUpdated.bindEvent(this.cataclysmUpdatedEvent.bind(this));

    }

    componentDidMount() {
        console.log("SETTINGS MOUNTED");
        this.getCataclysmConfig();
    }

    getCataclysmConfig(){

        var config = this.core.getCataclysmConfig();
        if(!config){
            console.log("CONFIG IS EMPTY");
            return;
        }

        console.log("GOT CONFIG IN REACT ELEMENT",config);

        this.setState({
            target_channel: config.target_channel,
            allow_banned_players_to_enter: config.allow_banned_players_to_enter,
            allow_banned_players_to_chat: config.allow_banned_players_to_chat,
            allow_players_to_join: config.allow_players_to_join,
            max_players: config.max_players,
            tiltify_campaign_id: config.tiltify_campaign_id,
            silent_chatbot_mode: config.silent_chatbot_mode,
        });


        console.log("STATE UPDATED!>");
    }

    componentWillUnmount() {
        this.getCataclysmConfig();
    }

    cataclysmUpdatedEvent(){

    }

    onTextChanged(event){
        this.setState({[event.target.id]:event.target.value,unsavedChanges:true,saveSuccessful:false});
    }

    onChkChanged(event){
        this.setState({[event.target.id]:!this.state[event.target.id],unsavedChanges:true,saveSuccessful:false});
    }

    onNumberChanged(event){
        var newVal = event.target.value;
        if(newVal == ''){
            newVal = 1;
        }
        if(newVal<=0){
            newVal = 1;
        }
        newVal = parseInt(newVal);
        if(!Number.isInteger(newVal)){
            newVal = this.state[event.target.id];
        }

        this.setState({[event.target.id]:newVal,unsavedChanges:true,saveSuccessful:false});
    }

    saveChanges(){
        console.log("SAVE CHANGES");
        var config = {
            target_channel: this.state.target_channel,
            allow_banned_players_to_enter: this.state.allow_banned_players_to_enter,
            allow_banned_players_to_chat: this.state.allow_banned_players_to_chat,
            allow_players_to_join: this.state.allow_players_to_join,
            silent_chatbot_mode: this.state.silent_chatbot_mode,
            max_players: this.state.max_players,
            tiltify_campaign_id: this.state.tiltify_campaign_id,
        }
        this.core.updateCataclysmConfig(config,this.saveResults.bind(this));
    }

    saveResults(res){
        console.log("GOT SAVE RESULTS",res);
        if(res=='success'){
            this.setState({saveSuccessful:true,unsavedChanges:false});
        }
    }

    render() {
            return(
                <div className="container text-black">
                    <Breadcrumb title="Cataclysm Settings" pageTree={{Dashboard:'#',Cataclysm:'#',Settings:'#'}}> </Breadcrumb>

                    <div className="btn btn-warning text-black"><i className="mdi mdi-arrow-left me-2"></i>BACK</div>

                    {this.state.unsavedChanges ?
                        <div className="alert alert-warning mt-2 text-black">
                            <div className='card-header d-inline'>
                            <div className='float-left d-inline'> 
                                    <i className="mdi mdi-alert me-2"></i>You have unsaved changes!
                                </div>
                                <div className="float-right d-inline " style={{top:'-5px',position:'relative'}}>
                                    <div className='btn btn-success btn-sm' onClick={this.saveChanges.bind(this)}>Save</div>
                                </div>
                            </div>
                        </div>
                        : null}

                    {this.state.saveSuccessful ?
                        <div className="alert alert-success mt-2 text-black">
                            <div className='card-header d-inline'>
                            <div className='float-left d-inline'> 
                                    <i className="mdi mdi-check me-2"></i>Save successful!
                                </div>
                               
                            </div>
                        </div>
                        : null}
                    
                    <h2>Stream & Chatbot Settings:</h2>

                    <div className="ms-4">
                        <div className="mb-2">
                            <label for="target_channel " className="form-label">Target Twitch Channel:</label>
                            <input type="text" id="target_channel" className="form-control max-width-400 m-0" value={this.state.target_channel} onChange={this.onTextChanged.bind(this)}/>
                            <p>
                            <small className="text-danger">This should almost always be your channel unless you're streaming on someone elses channel. For example: If you're streaming at <strong>https://www.twitch.tv/jonsandman</strong> then this needs to say <strong>jonsandman</strong>... Pointing your chatbot at someone else's channel without permission is against our TOS.</small>
                            </p>

                        </div>

                        <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="allow_banned_players_to_enter" checked={this.state.allow_banned_players_to_enter} onChange={this.onChkChanged.bind(this)}/>
                                <label className="form-check-label" for="allow_banned_players_to_enter">Allow Banned Viewers To !Enter to enter giveaways</label>
                        </div>

                        <div className="form-check mt-2">
                                <input type="checkbox" className="form-check-input" id="allow_banned_players_to_chat" checked={this.state.allow_banned_players_to_chat} onChange={this.onChkChanged.bind(this)}/>
                                <label className="form-check-label" for="allow_banned_players_to_chat">Allow Banned Viewers To Chat (Even though they are banned)</label>
                        </div>

                        <div className="form-check mt-2">
                                <input type="checkbox" className="form-check-input" id="silent_chatbot_mode" checked={this.state.silent_chatbot_mode} onChange={this.onChkChanged.bind(this)}/>
                                <label className="form-check-label" for="silent_chatbot_mode">Silent Chat Bot Mode (NO TWITCH MESSAGES/TIMEOUTS/BANS)</label>
                        </div>
                    </div>

                    <h2 className="mt-4">LDTP Cataclysm Realm Settings:</h2>
                    <div className="ms-4">
                    <h3 className="">Players</h3>


                    <div className="ms-4">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input " id="allow_players_to_join" checked={this.state.allow_players_to_join} onChange={this.onChkChanged.bind(this)}/>
                            <label className="form-check-label" for="allow_players_to_join">Allow Players to Join</label>
                        </div>


                        <div className="mb-2 mt-2">
                            <label for="max_players" className="form-label">Max Players:</label>
                            <input type="number" step="1" id="max_players" className="form-control max-width-400"  value={this.state.max_players} onChange={this.onNumberChanged.bind(this)}/>
                        </div>


                    </div>

                    
                    <h3 className="">Donations/Tips/Subs</h3>

                    <div className="ms-4">

                        <div className="mb-2">
                                <label for="tiltify_campaign_id " className="form-label mb-0">Tiltify Campaign ID:</label>
                                <input type="text" id="tiltify_campaign_id" className="form-control max-width-400 m-0" value={this.state.tiltify_campaign_id} onChange={this.onTextChanged.bind(this)} placeholder='DISABLED'/>
                                <p>
                                <small className='text-info'><i className='mdi mdi-information'></i>Tiltify donations will appear as normal donations</small>
                                <br></br>
                                <small className="text-danger">Leave Blank To Disable</small>
                                <br></br>
                                <small className="text-danger">Requries setting up a webhook on tiltify.com see additional info here: (COMING SOON)</small>
                                </p>

                        </div>
                    </div>
                    </div>

                    <div className='w-100 text-center mt-4'>
                        <div className='btn btn-success btn-lg' style={{width:'300px'}} onClick={this.saveChanges.bind(this)}>Save</div>
                    </div>



                </div>
            
            );
    }
}
export default CataclysmSettings;
