import React from 'react';

class ImageFeatured extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      return (
          <section className="py-5 bg-light-lighten border-top border-bottom border-light">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="text-center">
                              <h3>Flexible <span className="text-primary">Layouts</span></h3>
                              <p className="text-muted mt-2">There are three different layout options available to cater need for
                                  any <br /> modern web
                                  application.</p>
                          </div>
                      </div>
                  </div>

                  <div className="row mt-4">
                      <div className="col-lg-4">
                          <div className="demo-box text-center">
                              <img src="assets/images/layouts/layout-1.png" alt="demo-img"
                                  className="img-fluid shadow-sm rounded"/>
                              <h5 className="mt-3 f-17">Vertical Layout</h5>
                          </div>
                      </div>

                      <div className="col-lg-4">
                          <div className="demo-box text-center mt-3 mt-lg-0">
                              <img src="assets/images/layouts/layout-2.png" alt="demo-img"
                                  className="img-fluid shadow-sm rounded"/>
                              <h5 className="mt-3 f-17">Horizontal Layout</h5>
                          </div>
                      </div>
                      <div className="col-lg-4">
                          <div className="demo-box text-center mt-3 mt-lg-0">
                              <img src="assets/images/layouts/layout-3.png" alt="demo-img"
                                  className="img-fluid shadow-sm rounded"/>
                              <h5 className="mt-3 f-17">Detached Layout</h5>
                          </div>
                      </div>
                  </div>

                  <div className="row mt-4">

                      <div className="col-lg-4">
                          <div className="demo-box text-center">
                              <img src="assets/images/layouts/layout-5.png" alt="demo-img"
                                  className="img-fluid shadow-sm rounded"/>
                              <h5 className="mt-3 f-17">Light Sidenav Layout</h5>
                          </div>
                      </div>
                      <div className="col-lg-4">
                          <div className="demo-box text-center mt-3 mt-lg-0">
                              <img src="assets/images/layouts/layout-6.png" alt="demo-img"
                                  className="img-fluid shadow-sm rounded"/>
                              <h5 className="mt-3 f-17">Boxed Layout</h5>
                          </div>
                      </div>

                      <div className="col-lg-4">
                          <div className="demo-box text-center mt-3 mt-lg-0">
                              <img src="assets/images/layouts/layout-4.png" alt="demo-img"
                                  className="img-fluid shadow-sm rounded"/>
                              <h5 className="mt-3 f-17">Semi Dark Layout</h5>
                          </div>
                      </div>
                  </div>

              </div>
          </section>
      );
    }
}

export default ImageFeatured;
