import React from 'react';

class RightMenuLoggedIn extends React.Component {

    constructor(props) {
        super(props);

        this.myAccountClickedRef = this.myAccountClicked.bind(this);


    }

    myAccountClicked(){
        this.props.app.setNewMainPage("login");
    }

    render() {
      return (
          <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item me-0">

                  <button onClick={this.myAccountClickedRef} className="btn btn-success text-dark btn-rounded d-none d-lg-inline-flex fw-bold">
                      <div style={{position:'relative',top:'4px'}}>My Account</div> <i className="mdi mdi-arrow-right mdi-24px ms-2" style={{position:'relative',top:'2px'}}></i> 
                  </button>
              </li>
          </ul>
      );
    }
}

export default RightMenuLoggedIn;
