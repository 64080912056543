{
  "name": "LDS_Website",
  "version": "1.0.0",
  "license": "",
  "type": "module",
  "devDependencies": {
    "@types/react": "^17.0.13",
    "@types/react-dom": "^17.0.8",
    "buffer": "^5.7.1",
    "events": "^3.3.0",
    "https-browserify": "^1.0.0",
    "parcel": "^2.8.3",
    "process": "^0.11.10",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "stream-http": "^3.2.0",
    "typescript": "^4.3.5",
    "url": "^0.11.0"
  },
  "scripts": {
    "dev": "node generate-build-version-dev && parcel src/index.html --dist-dir dev",
    "production": "parcel build src/index.html && node generate-build-version-dist"
  },
  "dependencies": {
    "assets": "^3.0.1",
    "axios": "^1.3.2",
    "dexie": "^3.2.4",
    "jquery": "^3.7.0",
    "react": ">=15",
    "react-dom": "^17.0.2",
    "react-router-dom": "^5.2.0"
  }
}
