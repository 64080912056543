import React from 'react';

import LoginCard from './login_view/LoginCard'


class LoginView extends React.Component {

    componentDidMount() {
        
    }

    constructor(props) {
        super(props);

    }

    render() {

        return (
          <div className="loginbackground">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-xl-5 col-lg-5 col-md-4">
                          <div className="card o-hidden border-0 shadow-lg my-5">
                              <div className="card-body">
                                <LoginCard LDSCore={this.props.LDSCore } app={this.props.app }/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      );
    }
}

export default LoginView;
