
import Verification from "./../../helpers/Verification.js";


export default class User{

    constructor(config,eventSystem,webRequest){

        this.config = config;
        this.events = eventSystem;

        this.webRequest = webRequest;

        //Create Events
        this.events.createEvent('userUpdated'); //Users Profile
        this.events.createEvent('userStateChanged'); //Authed user

        //Bind events that we care about
        this.events.preemptiveBind('authChanged',this.authChanged.bind(this));
      
        this.user = {
            base: null,
            profile: null,
            private: null
        };

        //Notify the event system we want a post login callback
    }

    connect(){
        //make initial connections
    }

    authChanged(hasAuth){
        if(hasAuth){

            if(this.user.base==null){
                this.downloadUserData();
            }

            if(this.user.profile==null){
                this.downloadUserProfile();
            }

            if(this.user.private==null){
                this.downloadUserPrivateProfile();
            }

        }
    }

    doesUserHaveAbility(name){
        console.log("Check ability");
        console.log(this.user.base);
    }

    //Grab user data post login
    userStateChanged(){
        //TODO
    }

    getMissingAccountData(){
        //TODO
    }

    getUserAccountAttribute(attribute){
        //TODO
    }

    downloadUserProfile(){
        this.webRequest.basic('user/get_own_public_profile',this.processUserProfile.bind(this),null,null);
    }

    downloadUserPrivateProfile(){
        this.webRequest.basic('user/get_own_private_profile',this.processUserPrivateProfile.bind(this),null,null);
    }

    downloadUserData(){
        this.webRequest.basic('user/get_own_base',this.processUserBase.bind(this),null,null)
    }

    /*
    * private Function
    * Process the data and populate accountData map
    * @param object data: an object containing the users account data
    * return nothing, instead populate the accountData map
    */
    processUserBase(res){
        console.log("GOT USER BASE DATA",res);
        if(res!=undefined && res.data!=undefined){
            this.user.base = res.data;
            this.events.userUpdated.trigger(this.user);
        }
    }

    processUserProfile(res){
        console.log("GOT USER Profile DATA",res);
        if(res!=undefined && res.data!=undefined){
            this.user.profile = res.data;
            this.events.userUpdated.trigger(this.user);
        }
    }
    
    processUserPrivateProfile(res){
        console.log("GOT USER Private Profile DATA",res);
        if(res!=undefined && res.data!=undefined){
            this.user.private = res.data;
            this.events.userUpdated.trigger(this.user);
        }
    }


    /*
    * [Public Function](Async)
    * Verify a value meets criteria and set a value on the user's account
    * @param string attribute: the attribute name
    * @param string data: the attribute value
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    setAccountAttribute(attribute, value, callback){
        //TODO
    }

    /*
    * [Public Function](Async) Verify and set the accounts display name
    * @param string name: desired display name
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    setAccountDisplayName(name,callback){
        //TODO
    }

    /*
    * [Private Function] Process the display name change callback
    * @param bool result: [True/False] if successfully changed
    * @param string message: and error message or an empty string
    */
    setAccountDisplayNameCallback(result,message){
       //TODO
    }


    /*
    * [Public Function](Async) Set the users tos and accept time
    * @param string tos_version: the version that was accepted
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    setAndAcceptTOS(tosVersion,callback){
       var params = {tos_version:tosVersion};
       this.webRequest.basic('user/accept_tos',this.setAndAcceptTOSCallback.bind(this,callback),params,null);
    }

    /*
    * [Private Function] Process the tos accept callback
    * @param bool result: [True/False] if successfully changed
    * @param string message: and error message or an empty string
    */
    setAndAcceptTOSCallback(callback,res){
        console.log("SET TOS CALLBACK",res);
        if(res!=undefined && res.status == 'success'){
            if(res.data!=undefined && res.data.new_tos_version != undefined){
                console.log("TOS CHANGE ACCEPTED!");
                this.user.base.tos_version = res.data.new_tos_version;
                callback(true,'');
                return;
            }
        }
        callback(false,'Something went wrong updating your TOS version please try again later!');
        
    }

    getUserData(callback){
        return this.user;
   }

   setAccountDisplayName(name,callback){
    //TODO set display name
    }

    setAccountPassword(name,callback){
    //TODO set display name
    }

    setAccountEmail(name,callback){
        //TODO set display name
    }




}
