import React from 'react';

import LoadingScreen from './LoadingScreen';

class PasswordReset extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            error: '',
        };
        this.LoginResetRef = this.handleResetSubmit.bind(this);

        this.PasswordChangeRef = this.handlePasswordChange.bind(this);
        this.PasswordChangeRef2 = this.handlePassword2Change.bind(this);
        this.loginButtonRef = this.loginButtonPressed.bind(this);

        this.params = this.props.app.urlParams;
        this.resetToken = this.params.get('reset_token');
        this.displayName = this.params.get('display_name');
    }

    
    componentDidMount() {
        this._ismounted = true;
        console.log("PASSWORD RECOVERY MODE");

        document.getElementById('password_form').hidden=false;
        document.getElementById('verify_submit_loading').hidden=true;
        document.getElementById('verify_success_message').hidden=true;
        document.getElementById('password_missmatch').hidden=true;
        document.getElementById('failed_message').hidden=true;

    }

    componentWillUnmount() {
        this._ismounted = false;
    }


    handlePasswordChange(event) {
      this.setState({password: event.target.value});
      document.getElementById('password_missmatch').hidden=true;

    }

    handlePassword2Change(event) {
      this.setState({password2: event.target.value});
      document.getElementById('password_missmatch').hidden=true;
    }

    handleResetSubmit(event){
        event.preventDefault();
        if( this.oobCode === undefined){
            console.log("Code undefined");
        }
        if(this.state.password == this.state.password2){

            
            const re = /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!$#%]).*$/;
            if(!re.exec(this.state.password)){
                this.setState({error: 'Password must: contain uppercase & lowercase letters, a number, a symbol, and at least 8 characters.'});
                return;
            }


            this.props.LDSCore.verifyPasswordReset(this.resetToken,this.state.password,this.verifyPasswordResetCallback.bind(this));

            if(this._ismounted){
                document.getElementById('password_form').hidden=true;
                document.getElementById('verify_submit_loading').hidden=false;
                document.getElementById('verify_success_message').hidden=true;
                document.getElementById('password_missmatch').hidden=true;
            }

        }else{
            document.getElementById('password_missmatch').hidden=false;
        }
        //console.log("attempt to create account");
    }

    verifyPasswordResetCallback(result,message){
        console.log("verifyResetCallback",result,message);
        if(result){
            if(this._ismounted){
                document.getElementById('password_form').hidden=true;
                document.getElementById('verify_submit_loading').hidden=true;
                document.getElementById('verify_success_message').hidden=false;
            }
        }else{
            if(this._ismounted){
                document.getElementById('verify_submit_loading').hidden=true;
                this.setState({error:message});
                document.getElementById('failed_message').hidden=false;
            }
        }

    }

    loginButtonPressed(){
        this.props.parent.selectLoginView();
    }

render() {
  return (
            <div>
              <br></br><br></br>


              <div id="password_form">

                  <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
                      <p></p>
                  </div>

                  <br></br>

                  <div className="alert alert-info mb-0 text-dark" role="alert">
                    <h3>Welcome back {this.displayName ? this.displayName : null}</h3>
                    <strong>Please enter a new password!</strong>
                  </div>

                  <br></br>

                  {this.state.error!="" ?
                    <div className='alert alert-danger text-dark'>
                        {this.state.error}
                    </div>
                    :
                    null
                    }

                  <div id="password_missmatch" className="alert alert-danger text-dark" role="alert" hidden>
                    <strong>Passwords don't match!</strong>
                  </div>

                  <br></br>

                  <form onSubmit={this.LoginResetRef}>

                      <div className="better-form-group">
                          <label htmlFor="identity">Password</label>
                          <input type="password" className="better-form-control " id="password" placeholder="Password" value={this.state.password} onChange={this.PasswordChangeRef}/>
                      </div>

                      <div className="better-form-group">
                          <label htmlFor="identity">Password Again</label>
                          <input type="password" className="better-form-control " id="password2" placeholder="Password" value={this.state.password2} onChange={this.PasswordChangeRef2}/>
                      </div>

                      <br></br>

                      <div className="row">
                            <div className="col-lg-6">
                                  <button className="btn btn-success w-100" >
                                      Reset Password
                                  </button>
                            </div>
                            <div className="col-lg-6">
                                  <button className="btn btn-light w-100" onClick={this.loginButtonRef}>
                                    Back To Login
                                </button>
                            </div>
                      </div>
                  </form>
              </div>

              <div id="verify_submit_loading" hidden>
                  <LoadingScreen loadingMessage="Updating Password!"/>
              </div>

               <div id="verify_success_message" hidden>
                   <div className="text-center">
                       <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
                       <p></p>
                   </div>

                   <div className="alert alert-success bg-success-lighten mb-0" role="alert">
                     <strong>Success: </strong> Your password has been reset! Please login using your new password.
                   </div>
                   <br></br>
                   <center>
                       <button className="btn btn-primary btn-lg btn-rounded" onClick={this.loginButtonRef}>
                           Login
                       </button>
                   </center>
               </div>

               <div id="failed_message" hidden>

                   <div className="text-center">
                       <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
                       <p></p>
                   </div>

                   <div className="alert alert-danger text-dark mb-0" role="alert">
                     <strong>Error: We couldn't reset your password: </strong> 
                     <br></br>
                     {this.state.error}

                   </div>

                   <br></br>
                   <center>
                       <button className="btn btn-danger" onClick={this.loginButtonRef}>
                           Return to Login
                       </button>
                   </center>
               </div>


              <br></br><br></br>
              </div>


  );
    }
}

export default PasswordReset;
