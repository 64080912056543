import React from 'react';
import ReactDOM from "react-dom";

import LoadingScreen from './LoadingScreen';
import Error from './Error';

//Login Methods
import DefaultLogin from './login_methods/DefaultLogin';


class Login extends React.Component {

    componentDidMount() {
        //Really just for devs

    }

    constructor(props) {
        super(props);

        this.retryRef = this.retryLogin.bind(this);
        this.loginError = "Error: Could not login!";

    }


    attemptLogin(identity,password){
        console.log("Attemting Login");
        this.props.LDSCore.signInWithEmailAndPassword(identity, password, this.loginCallback.bind(this));
        document.getElementById('login_form_message').hidden = false;
        document.getElementById('login_form').hidden = true;
    }

    loginCallback(result,message){
        console.log("login results",result,message);
        if(result){
            
        }else{
            this.loginError = message;
            document.getElementById('login_form_message').hidden = true;
            document.getElementById('login_error').hidden = false;
            this.forceUpdate();
        }
    }

    retryLogin(){
        document.getElementById('login_form_message').hidden = true;
        document.getElementById('login_form').hidden = false;
        document.getElementById('login_error').hidden = true;
    }



render() {
  return (
      <div className="card-body">
          <br></br><br></br>


          <div id="login_form">

              <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
              </div>

              <DefaultLogin parent={this} config={this.configFeatures} LDSCore={this.props.LDSCore} />

              <div id="dev_error"></div>

          </div>

          <div id="login_form_message" hidden>
              <LoadingScreen loadingMessage="Logging you in!"/>
          </div>

          <div id="login_error" hidden>
            <Error loadingMessage={this.loginError}/>

            <center>
                <button onClick={this.retryRef} className="btn btn btn-warning btn-rounded d-none d-lg-inline-flex">
                    <i className="mdi mdi-reload"></i> Retry
                </button>
            </center>
          </div>


          <br></br>
        </div>

  );
    }
}

export default Login;
