import React from 'react';

import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'

import { config } from "./../../../config";


class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };


    }



    render() {
      return (
          <nav id="home" className="navbar navbar-expand-lg py-lg-3 navbar-dark bg-dark">
              <div className="container">

                  <a href="index.html" className="navbar-brand me-lg-5">
                      <img src={config.paths.cdn_base+"/images/last_day_studios_logo.png"} alt="" className="logo-dark" height="40" />
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="mdi mdi-menu"></i>
                  </button>


                  <div className="collapse navbar-collapse" id="navbarNavDropdown">


                     <LeftMenu  LDSCore={ this.props.LDSCore } app={this.props.app}/>

                     <RightMenu LDSCore={ this.props.LDSCore } app={this.props.app}/>
                  </div>
              </div>
          </nav>

      );
    }
}

export default NavBar;
