import React from 'react';

import Breadcrumb from '../components/Breadcrumb';
import LinkSteam from '../components/LinkedAccountCards/LinkSteam';
import LinkTwitchBasic from '../components/LinkedAccountCards/LinkTwitchBasic';
import LinkTwitchAdvanced from '../components/LinkedAccountCards/LinkTwitchAdvanced';
import LinkedAccountCard from '../components/LinkedAccountCards/LinkTwitchBasic';
import LinkStreamLabs from '../components/LinkedAccountCards/LinkStreamLabs';
import LinkStreamElements from '../components/LinkedAccountCards/LinkStreamElements';
import LinkLDSModAccount from '../components/LinkedAccountCards/LinkLDSModAccount';
import Footer from '../../both/Footer';
import LinkYouTube from '../components/LinkedAccountCards/LinkYouTube';

class LinkAccount extends React.Component {

    constructor(props) {
        super(props);

        //Alias
        this.core = this.props.LDSCore;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
            return(
                <div>
                <div className='content'>
                    <div className='container-fluid text-dark'>

                        <Breadcrumb title="Link Account" pageTree={{Dashboard:'#','Linked Account':'#'}}> </Breadcrumb>

                        <h3>Player Services:</h3>
                        <hr></hr>
                    
                        <LinkTwitchBasic LDSCore={this.core} />
                        <LinkSteam LDSCore={this.core} disabled={true}/>

                        <h3>Cataclysm Services:</h3>
                        <hr></hr>
                        <div className="alert alert-info bg-info-lighten text-black border-0" role="alert">
                            <i className="mdi mdi-information-outline pe-2"></i><strong>Check out our <a href="">Privacy Policy</a></strong> - for information about how we use your data.
                        </div>

                        <LinkTwitchAdvanced LDSCore={this.core} />
                        <LinkStreamLabs LDSCore={this.core} disabled={true}/>
                        <LinkYouTube LDSCore={this.core} />
                        <LinkStreamElements LDSCore={this.core} />

                        {true ? 
                        <div>
                            <h3>Admin</h3>
                            <LinkLDSModAccount LDSCore={this.core} disabled={true}/>
                        </div>
                        : null }
                        
                        <br></br>
                    </div>
                </div>
                <Footer></Footer>
            </div>
            );
    }
}
export default LinkAccount;
