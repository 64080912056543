import axios from 'axios';
import isDev from "./DetectDev.js"
import isNode from "./DetectNode.js"

export class WebRequest {

    constructor(config,httpsLibrary = null){
        this.config = config;
        this.httpsLibrary = httpsLibrary;

        this.apiPath = this.getAPIPath();

        this.httpAgent = null;
        this.ready = false;

        this.token = null;

        if(isNode()){
            console.log("NODE DETECTED: SETTING UP WEB REQUEST FOR NODE!");
            this.createHttpAgent(); 
        }else{
            this.token = localStorage.getItem('_token');
            this.ready = true;
        }
    }

    setToken(token){
        this.token = token;
    }

    async createHttpAgent(){
        //ON node js you have to pass in a //https = await import('node:https'); as httpsLibrary
        if(!this.httpsLibrary){
            console.log("YOU MUST PASS IN A HTTPS CLIENT FOR NODE ENVS!!!");
            throw new Error('YOU MUST PASS IN A HTTPS CLIENT FOR NODE ENVS!!!');
        }
        this.httpAgent = new this.httpsLibrary.Agent({rejectUnauthorized: false});
        this.ready = true;
    }

          
    async basic(path,callback,params = null,data = null)
    {
        await this.waitForReady();

        var fullURL = this.combineBaseAndApiPath(this.apiPath,path);

        console.log("Making request to:",fullURL);

        var headerObj = {
            'Access-Control-Allow-Origin' : '*',
        }

        if(this.token!=undefined){
            headerObj.Authorization = `Bearer ${this.token}`;
        }

        console.log("Sending request with token",this.token);

        var axiosConfig = {
            method: 'post',
            url: fullURL,
            params: params,
            responseType: 'json',
            headers: headerObj,
            };

        if(this.httpAgent!=null){
            axiosConfig.httpsAgent = this.httpAgent;
        }

        axios(axiosConfig).then((res) => {
            let status = res.status;
            if(status==200){
                callback(res.data);
            }else{
                callback({status:'exception','message':'The request failed with status other than 200!'});
            }
        }).catch((error) => {
                console.log("WEB REQUEST ERROR:",error);
                callback({status:'exception','message':'The request failed!',data:error,path:fullURL});
        });

    }

    async custom(fullURL,callback,params = null)
    {
        await this.waitForReady();
        console.log("Making request to:",fullURL);

        var axiosConfig = {
            method: 'get',
            url: fullURL,
            params: params,
            responseType: 'json',
            };

        if(this.httpAgent!=null){
            axiosConfig.httpsAgent = this.httpAgent;
        }

        axios(axiosConfig).then((res) => {
            callback(res);
        }).catch((error) => {
            console.log("GOT ERROR",error);
            callback(null);
        })

    }

    getAPIPath(){
        if(isDev()){
            return this.config.paths.apiURLDev;
        }else{
            return this.config.paths.apiURL;
        }
    }

    async waitForReady(){
        if(!this.ready){
            console.log("WEB REQUEST NOT READY!!");
            while(!this.ready){
                console.log("STILL NOT READY");
                await this.sleep(1000);
            }
        }
    }

    sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      }

    combineBaseAndApiPath(base,api){
        var middle = '/';
        if(base.slice(-1)=='/' || api.charAt(0)=='/'){
            middle = '';
        }
        var fullURL = base+middle+api;
        return fullURL;
    }

}
