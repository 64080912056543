import React from 'react';

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      return (

          <section className="py-5 bg-danger-lighten sectionbackground">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="text-center text-white">
                              <h1 className="mt-0"><i className="mdi mdi-frequently-asked-questions"></i></h1>
                              <h3>Frequently Asked <span className="text-warning">Questions</span></h3>
                              <p className="text-white mt-2">Check out these common questions and answers.
                                  <br></br>Otherwise shoot us a tweet or email.</p>

                              <button type="button" className="btn btn-success btn-sm mt-2"><i className="mdi mdi-email-outline me-1"></i> Email us your question</button>
                              <button type="button" className="btn btn-info btn-sm mt-2 ms-1"><i className="mdi mdi-twitter me-1"></i> Tweet @Jess_GGs </button>
                              <button type="button" className="btn btn-primary btn-sm mt-2 ms-1"><i className="mdi mdi-discord me-1"></i> Join Us On Discord </button>
                          </div>
                      </div>
                  </div>

                  <div className="row mt-5">
                      <div className="col-lg-5 offset-lg-1">

                          <div>
                              <div className="faq-question-q-box text-warning bg-dark">Q.</div>
                              <h4 className="faq-question text-warning">Can I use Last Stream Studio on my stream?</h4>
                              <p className="faq-answer mb-4 pb-1 text-white">Yes! As long as your a subscriber you're free to use Last Stream Studio on stream or in yt videos.
                                  You can find the full TOS <a className="text-success" href="https://themes.getbootstrap.com/licenses/" target="_blank">here</a>.</p>
                          </div>


                          <div>
                              <div className="faq-question-q-box text-warning bg-dark">Q.</div>
                              <h4 className="faq-question text-warning">When will Last Day To Party be done? What's the release date?</h4>
                              <p className="faq-answer mb-4 pb-1 text-white">We have no idea! We're working as fast as we can. We're more concerned about making sure the game
                              is as good as realistically possible.</p>
                          </div>
                      </div>


                      <div className="col-lg-5">

                      <div>
                          <div className="faq-question-q-box text-warning bg-dark">Q.</div>
                          <h4 className="faq-question text-warning">Help! I can't get X to work!</h4>
                          <p className="faq-answer mb-4 pb-1 text-white">Sorry! The game is in pre-alpha thre's bound to be a few bugs. Hop on our community Discord
                          and post a message in the #help channel. Jess or someone from the community will do their best to help you!</p>
                      </div>


                      <div>
                          <div className="faq-question-q-box text-warning bg-dark">Q.</div>
                          <h4 className="faq-question text-warning">Have another question?</h4>
                          <p className="faq-answer mb-4 pb-1 text-white">Reach out! We'll do our best to answer it!</p>
                      </div>

                      </div>

                  </div>


              </div>
          </section>

      );
    }
}

export default Contact;
