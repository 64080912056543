import React from 'react';

class CreateAccount extends React.Component {

    componentDidMount() {
    }

    constructor(props) {
        super(props);

        this.core = this.props.LDSCore;

        this.state = {
            identity: '',
            displayName: '',
            password: '',
            passwordMismatch: false,
            createAccountError: false,
            errorMessage: '',
        };
        this.LoginSubmitRef = this.handleLoginSubmit.bind(this);
        this.LoginChangeRef = this.handleLoginChange.bind(this);
        this.PasswordChangeRef = this.handlePasswordChange.bind(this);
        this.PasswordChangeRef2 = this.handlePassword2Change.bind(this);
    }

    handleLoginChange(event) {
        this.setState({identity: event.target.value});
    }

    handleDisplayNameChange(event) {
        this.setState({displayName: event.target.value});
    }

    handlePasswordChange(event) {
        var newMatchValue = this.state.passwordMismatch && event.target.value!=this.state.password2;
        this.setState({password: event.target.value,passwordMismatch:newMatchValue});
    }

    handlePassword2Change(event) {
        var newMatchValue = this.state.passwordMismatch && this.state.password!=event.target.value;
        this.setState({password2: event.target.value,passwordMismatch:newMatchValue});
    }

    handleLoginSubmit(event){
        event.preventDefault();
        console.log(this.state.identity,this.state.password)
        if(this.state.password != this.state.password2){
            console.log("Password Missmatch");
            this.setState({passwordMismatch:true});
            return;
        }

        const re = /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!$#%]).*$/;
        if(!re.exec(this.state.password)){
            this.setState({createAccountError: true, errorMessage:'Password must: contain uppercase & lowercase letters, a number, a symbol, and at least 8 characters.'});
            return;
        }

        if(!validateEmail(this.state.identity)){
            this.setState({createAccountError: true, errorMessage:'Must be a valid email address'});
            return;
        };

        if(!(this.state.displayName!='' && this.state.displayName.length>3)){
            this.setState({createAccountError: true, errorMessage:'Display name must be at least 4 characters'});
            return;
        }

        this.setState({passwordMismatch:false});
        this.core.createAccount(this.state.displayName,this.state.identity,this.state.password,this.accountCreatedCallback.bind(this));
        console.log("attempt to create account");

    }

    accountCreatedCallback(status,message){
        console.log("Callback triggered",status,message);
        if(status==false){
            this.setState({createAccountError: true, errorMessage:message});
        }
    }

    cancel(){
        this.props.parent.selectLoginView();
    }

render() {
  return (
            <div>
              <br></br><br></br>
              <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">Last Day Studios</h1>
              </div>

              { this.state.createAccountError ?
                <div className="alert alert-danger  bg-danger-lighten" role="alert">
                    <i className="dripicons-warning mr-2"></i> Error: {this.state.errorMessage}
                 </div>
                : null }

              <form onSubmit={this.LoginSubmitRef}>

                <div className="better-form-group">
                      <label htmlFor="display_name">Display Name</label>
                      <input type="text" id="display_name" className="better-form-control  " aria-describedby="Display Name" placeholder="Display Name" value={this.state.displayName} onChange={this.handleDisplayNameChange.bind(this)}/>
                </div>

                <div className="better-form-group">
                    <label htmlFor="identity">Email</label>
                    <input type="text" id="identity" className="better-form-control  " aria-describedby="Username or Email" placeholder="Email" value={this.state.identity} onChange={this.LoginChangeRef}/>
                </div>

                { this.state.passwordMismatch ?
                    <div className="alert alert-warning bg-warning-lighten" role="alert">
                        <i className="dripicons-warning mr-2"></i> Passwords don't match!
                    </div>
                    : null }

                <div className="better-form-group">
                    <label htmlFor="identity">Password</label>
                    <input type="password" className="better-form-control " id="password" placeholder="Password" value={this.state.password} onChange={this.PasswordChangeRef}/>
                    <small>Required: Uppercase & lowercase letters, a number, a symbol, and at least 8 characters.</small>
                </div>


                <div className="better-form-group">
                    <label htmlFor="identity">Password Again</label>
                    <input type="password" className="better-form-control " id="password2" placeholder="Password" value={this.state.password2} onChange={this.PasswordChangeRef2}/>
                </div>
                    <br></br>

                <button className="btn btn-success btn-user w-100" >
                    Register
                </button>
    



              </form>


            <br></br>
              <div className="btn btn-secondary w-100" onClick={this.cancel.bind(this)}>Cancel</div>


              <br></br><br></br>
              </div>


  );
    }
}

export default CreateAccount;

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };