
import React from 'react';

class TokenSale extends React.Component {

    constructor(props) {
        super(props);

        this.price = this.props.price;
        this.amount = this.props.amount;

        this.state = {
        };
    }

    render() {
      return (
            <div className="card border border-dark m-1 text-white bg-dark" style={{maxWidth:'300px'}}>
                <center><h1>{this.amount}</h1></center>
                <center><h2>Red Soul Cores</h2></center>
                
                <img src='https://ldssite.b-cdn.net/images/red_soul_coin_256.png'></img>
                <br></br>
                <center><h4>${this.price}</h4></center>
                
            </div>
      );
    }
}

export default TokenSale;

