import React from 'react';

class DefaultLogin extends React.Component {

    componentDidMount() {

    }

    constructor(props) {
        super(props);
        this.state = {
            identity: '',
            password: ''
        };
        this.LoginSubmitRef = this.handleLoginSubmit.bind(this);
        this.LoginChangeRef = this.handleLoginChange.bind(this);
        this.PasswordChangeRef = this.handlePasswordChange.bind(this);

        this.createAccountClickedRef = this.handleCreateAccountClicked.bind(this);
        this.forgotPasswordRef = this.handleForgotPasswordClicked.bind(this);
    }

    handleLoginChange(event) {
      this.setState({identity: event.target.value});
    }

    handlePasswordChange(event) {
      this.setState({password: event.target.value});
    }

    handleLoginSubmit(event){
        event.preventDefault();
        console.log(this.state.identity,this.state.password)
        //TODO check for valid info before submitting login request
        console.log("CALLING ATTEMPT LOGIN!");
        this.props.parent.attemptLogin(this.state.identity,this.state.password);
        this.state.identity = "";
        this.state.password = "";
    }

    handleCreateAccountClicked(){
        this.props.parent.props.parent.createAccountClicked();
    }

    handleForgotPasswordClicked(){
        this.props.parent.props.parent.forgotPasswordClicked();
    }


    render() {
          return (
                <div>
                    <hr></hr>
                    <div className="text-center">
                        <h4>Login with your Last Day Studios Account</h4>
                    </div>
                    <br></br>

                      <form onSubmit={this.LoginSubmitRef}>

                          <div className="better-form-group">
                              <label htmlFor="identity">Email</label>
                              <input type="text" id="identity" className="better-form-control  " aria-describedby="Username or Email" placeholder="Email" value={this.state.identity} onChange={this.LoginChangeRef}/>
                          </div>

                          <div className="better-form-group">
                              <label htmlFor="identity">Password</label>
                              <input type="password" className="better-form-control " id="password" placeholder="Password" value={this.state.password} onChange={this.PasswordChangeRef}/>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                                      <button className="btn btn-primary btn-user btn-block" >
                                          Login
                                      </button>
                             </div>

                              <div className="col-lg-6">
                                <div className="float-right">
                                      <button className="btn" onClick={this.forgotPasswordRef}>Forgot Password?</button><br></br>
                                      <button className="btn text-info bold" onClick={this.createAccountClickedRef} >Create an Account</button>
                                </div>
                              </div>

                          </div>
                      </form>
                      <br></br>
                  </div>
          );

    }
}

export default DefaultLogin;
