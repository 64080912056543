
//Cloud provider
//import Cloud from "./src/google_cloud/Cloud";
import LDSBackend from "./src/lds_backend/LDSBackend.js";

//LDS classes
import EventManager from "./src/helpers/EventManager.js";

//Helpers
import isDev from "./src/helpers/DetectDev.js"


/*
* LDSCore API Interface
*/
export class LDSCore {

    constructor(config,httpsLibrary=null){

        this.config = config;
        console.log("init core");

        //A helper class that stores all of the event handles this.events.eventName
        this.events = new EventManager;

        this.backend = new LDSBackend(this.config,this.events,httpsLibrary);

        if(this.isDevelopmentMode()){
            console.log("!!! Running in Dev Mode !!!");
        }
        

    }

    /*
    * [Public Function] Bind an event to be fired when the user state changes
    * @param function event: the function to call
    * return string event_id: event id to reference this bound event, null if it failed to bind
    */
   isDevelopmentMode(){
       return isDev();
   }

   connect(){
        this.backend.connect();
   }

   /*
   * [Public Function] Get an attribute from the global config
   * @param string attribute: the attribute name
   * return string: value
   */
   getConfigValue(attributeName){
       return this.backend.globalConfig.getConfigValue(attributeName);
   }

   /*
   * [Public Function] Get an array of missing attribute names on the user's account
   * @param string attribute: the attribute name
   * return string array: ['attribute_name_1','attribute_name_2']
   */
    getMissingAccountData(){
        return this.backend.user.getMissingAccountData();
    }

    /*
    * [Public Function] Get an attribute from the user's account
    * @param string attribute: the attribute name
    * return string: value
    */
    getUserAccountAttribute(attribute){
        return this.backend.user.getUserAccountAttribute(attribute);
    }

    /*
    * [Public Function] Get user object
    * return string: value
    */
    getUserData(){
        return this.backend.user.getUserData();
    }

    /*
    * [Public Function] Check if the user is logged in
    * return bool: [True/False]
    */
    isUserLoggedIn(){
        return this.backend.auth.isUserLoggedIn();
    }

    /*
    * [Public Function] Check if we're connected to the backend
    * return bool: [True/False]
    */
    isConnected(){
        return this.backend.isConnected();
    }

    /*
    * [Public Function] Verify if the display name meets our criteria
    * @param string name: desired display name
    * return string: "accepted" or an error message
    */
    verifyDisplayNameMeetsCriteria(name){
        return this.backend.user.verifyDisplayNameMeetsCriteria(name);
    }

    /*
    * [Public Function](Async) Verify and set the accounts display name
    * @param string name: desired display name
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    setDisplayName(name,callback){
        this.backend.user.setAccountDisplayName(name,callback);
    }

    /*
    * [Public Function](callback) Create Account
    * @param string email: desired email
    * @param string password: desired password
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    createAccount(displayName,email,password,callback){
        this.backend.auth.createAccount(displayName,email,password,callback);
    }

    /*
    * [Public Function](Async) Log the user in using an email and password
    * @param string email: the users email address
    * @param string password: the users password
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    signInWithEmailAndPassword(email,password,callback){
        this.backend.auth.signInWithEmailAndPassword(email,password,callback);
    }

    /*
    * [Public Function](Async) Logout the user
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    signOut(callback){
        this.backend.auth.signOut(callback);
    }

    /*
    * [Public Function](Async) Set the users TOS version and time accepted
    * @param string tos_version: the tos version accepted
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    setAndAcceptTOS(tosVersion,callback){
        this.backend.user.setAndAcceptTOS(tosVersion,callback);
    }

    /*
    * [Public Function](Async) Request Password Reset
    * @param string email address: the accounts email address
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    requestPasswordReset(email,callback){
        this.backend.auth.requestPasswordReset(email,callback);
    }

    /*
    * [Public Function](Async) Verify password reset by submitting a reset code and new password
    * @param string code: oobCode provided by the reset system
    * @param string newPassword: the new password the user wants
    * @param function callback(success,message): [bool: True/False] [string: message or error]
    */
    verifyPasswordReset(code,newPassword,callback){
        this.backend.auth.verifyPasswordReset(code,newPassword,callback);
    }

    /*
    * [Public Function] Request a 3rd party auth link
    * @param string serviceName: the service name of the link we're requesting
    * @param function callback(res): the json response of this event
    */
    get3rdPartyLink(serviceName,callback){
        this.backend.credentials.get3rdPartyLink(serviceName,callback);
    }

    /*
    * [Public Function] Saves any pending 3rd party auth attempts
    * @param function callback(res): the json response of this event
    */
    saveThirdPartyAuthAttempt(callback){
        this.backend.credentials.saveThirdPartyAuthAttempt(callback);
    }

    /*
    * [Public Function] Get a credential if it exists
    * @param string serviceName: the service of the requsted credential
    */
    getCredential(serviceName){
        return this.backend.credentials.getCredential(serviceName);
    }

    /*
    * [Public Function](Async) Delete a credential uuid
    * @param string uuid: the credential uuid
    * @param function callback(success): [bool: True/False] 
    */
    deleteCredential(uuid,callback){
        this.backend.credentials.deleteCredential(uuid,callback);
    }


    downloadCataclysmData(){
        this.backend.cataclysm.downloadCataclysmData();
    }

    getCataclysmValue(){
        return this.backend.cataclysm.getCataclysmValue();
    }

    isCataclysmActive(){
        return this.backend.cataclysm.isCataclysmActive();
    }

    cataclysmSignup(){
        this.backend.cataclysm.signup();
    }

    cataclysmShutdown(){
        this.backend.cataclysm.shutdown();
    }

    /*
    * [Public Function](Async) Bind and Initialize Multiplayer Services Instance
    * @param string uuid: the credential uuid
    * @param function callback(success): [bool: True/False] 
    */
    bindAndInitializeMPSInstance(instanceID,ip,port,name,callback){
        this.backend.multiplayerServics.bindAndInitializeMPSInstance(instanceID,ip,port,name,callback);
    }

    
    /*
    * [Public Function](Async) Allows the access token to be manually set
    * @param string token: the accesst token
    */
    setAccessToken(token){
        this.backend.webRequest.setToken(token);
    }

    get3rdPartyCredByServiceName(serviceName){
        return this.backend.multiplayerServics.get3rdPartyCredByServiceName(serviceName);
    }

    doesUserHaveAbility(name){
        return this.backend.user.doesUserHaveAbility(name);
    }

    updateCredential(newCred){
        this.backend.credentials.updateCredential(newCred);
    }

    getStoreGames(callback){
        this.backend.store.getStoreGames(callback);
    }

    getStoreProducts(callback){
        this.backend.store.getStoreProducts(callback);
    }

    getStoreItems(callback){
        this.backend.store.getStoreItems(callback);
    }

    getStoreGame(uuid){
        return this.backend.store.getStoreGame(uuid);
    }

    getStoreItem(uuid){
        return this.backend.store.getStoreItem(uuid);
    }

    getStoreProduct(uuid){
        return this.backend.store.getStoreProduct(uuid);
    }

    isProductInUsersLibrary(type,uuid){
        return this.backend.library.isProductInUsersLibrary(type,uuid);
    }

    getCataclysmConfig(){
        return this.backend.cataclysm.getCataclysmConfig();
    }

    updateCataclysmConfig(config,callback){
        this.backend.cataclysm.updateCataclysmConfig(config,callback);
    }

}
 