import React from 'react';
import { config } from "../../../../../config";
import PriceCard from './PriceCard';

class ItemCardSmall extends React.Component {

    constructor(props) {
        super(props);

        this.dashboard = this.props.dashboard;
        this.dashboard = this.props.dashboard;

        this.fullStoreEntry = this.props.storeItemEntry;
        //Defaults
        this.ribbonColor = 'ribbon-primary';
        this.currencyType = 'usd';
        this.productType = 'item';

        if(this.props.type){
            this.productType = this.props.type;
        }


        //Check for a full game entry to intialize from
        if(this.fullStoreEntry){
            this.coverImage = this.fullStoreEntry.icon_url_sm;

            if(this.fullStoreEntry.price==0.0){
                this.ribbonText = "Play For Free!";
                this.ribbonColor = 'ribbon-success';
            }

            this.productId = this.fullStoreEntry.uuid;
            this.productName = this.fullStoreEntry.item_name;
             this.productPrice = this.fullStoreEntry.price;
             if(this.fullStoreEntry.currency_type){
                this.currencyType = this.fullStoreEntry.currency_type;
             }
             this.discount = this.fullStoreEntry.discount;
            
        }

        
        this.state = {
        };
    }
    
    cardClicked(){
        this.dashboard.openProductPage(this.productType,this.productId);
    }

    render() {
        return (
            
            <div className="card ribbon-box game-item-card-sm clickable" onClick={this.cardClicked.bind(this)}>
                <div className="card-body p-0 m-0" >
                    <img className='game-item-card-bg  card-shimmer-effect' src={this.coverImage}></img>
                    { this.ribbonText ?
                    <div className={this.ribbonColor+" ribbon float-end mt-2 game-item-ribbon text-dark"}><i className="mdi mdi-google-controller me-1"></i>{this.ribbonText}</div>
                    : null}
                    </div>
                    <PriceCard price={this.productPrice} discount={this.discount} currencyType={this.currencyType} title={this.productName}></PriceCard>

            </div>
      );
    }
}

export default ItemCardSmall;